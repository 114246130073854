<!-- Entity and event name -->
<div style="font-weight: 500;">
    {{ displayedEntityAndEventName }}
</div>

<!-- Practice path -->
<div style="font-size: 0.9rem;">
    <div
        [ngSwitch]="practicePathCachingState"
        style="font-size: 0.9rem;"
    >
        <div
            *ngSwitchCase="EntityCachingState.CachedAndExist"
            (click)="openPracticePathLogsInDialog()"
            [class.clickable-text]="isLogEntitySameAsOpenedLoggedEntity(practicePathLog.loggedEntityKey) == false"
        >
            {{ practicePathName }}
        </div>

        <div *ngSwitchCase="EntityCachingState.CachedAndNotExist" style="font-style: italic;">
            <div>
                Nem létező vizsgaútvonal (valószínűleg törölve)
            </div>
            <div
                (click)="openPracticePathLogsInDialog()"
                [class.clickable-text]="isLogEntitySameAsOpenedLoggedEntity(practicePathLog.loggedEntityKey) == false"
            >
                {{ practicePathLog.loggedEntityKey }}
            </div>
        </div>

        <div *ngSwitchCase="EntityCachingState.ErrorDuringQuery" style="font-style: italic;">
            Hiba a vizsgaútvonal lekérdezés közben
        </div>
    </div>
</div>