import { NotifierService } from 'angular-notifier';
import { IconRecorderService, TimedIconRecording, TimedIconRecordingStatusManager } from './timed-icon-recorder.service';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-icon-recorder',
  templateUrl: './timed-icon-recorder.component.html',
  styleUrls: ['./timed-icon-recorder.component.scss']
})
export class TimedIconRecorderComponent implements OnInit {
  @Input()
  public iconRecorderService: IconRecorderService<TimedIconRecording>;

  @Output()
  onTapTime = new EventEmitter<number>();

  constructor(private notifier: NotifierService){

  }

  ngOnInit(): void {
  }

  onTapSaveRecording(practiceIconUrl: string){
    const isValid = this.iconRecorderService.isValidRecording(practiceIconUrl);
    if(!isValid){
      this.notifier.notify('error', 'A mentés nem lehetséges, mert a végpont nem lehet a kezdőpont előtt!');
      return;
    }

    this.iconRecorderService.finishRecording(practiceIconUrl);
  }

  onCancelRecording(practiceIconUrl: string){
    this.iconRecorderService.cancelRecording(practiceIconUrl);
  }

}
