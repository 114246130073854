import { PracticeCityZoneEditorModule } from './modules/practice-city-zone-editor/practice-city-zone-editor.module';
import { AnonymizerTasksTableComponent } from './components/dashboard/content/practice/anonymizer-tasks/components/anonymizer-tasks-table/anonymizer-tasks-table.component';
import { PlaygroundComponent } from './components/dashboard/content/special/playground/playground.component';
import { MrvegasMetadataViewerModule } from './modules/mrvegas-metadata-viewer/mrvegas-metadata-viewer.module';
// Built-in components and modules
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatNativeDateModule, MAT_DATE_LOCALE } from "@angular/material/core";
// Angular material modules
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import {ClipboardModule} from '@angular/cdk/clipboard';
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
// 3rd party modules
import { NgxEchartsModule } from "ngx-echarts";
// Custom modules
import { LoggerModule } from "src/app/modules/logger/logger.module";
import { CommentsOnEntityModule } from "src/app/modules/comments-on-entity/comments-on-entity.module";
// Custom pipes
import { MsToTimeStringPipe } from "./pipes/ms-to-time-string.pipe";
import { PractiseTimePipe } from "./pipes/practise-time.pipe";
import { PrefixWithDotsPipe } from "./pipes/prefix-with-dots.pipe";
import { TypeofPipe } from "./pipes/typeof.pipe";
import { SafePipe } from "./pipes/safe.pipe";
// Custom components
import { LoginComponent } from "./components/login/login.component";
import { PageNotFoundComponent } from "./components/page-not-found/page-not-found.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { HeaderComponent } from "./components/dashboard/header/header.component";
import { NavigationComponent } from "./components/dashboard/navigation/navigation.component";
import { ContentComponent } from "./components/dashboard/content/content.component";
import { WelcomeComponent } from "./components/dashboard/content/welcome/welcome.component";
import { StatisticsComponent } from "./components/dashboard/content/welcome/statistics/statistics.component";
import { CategoriesComponent } from "./components/dashboard/content/actual-questionbank/categories/categories.component";
import { CategoryComponent } from "./components/dashboard/content/actual-questionbank/categories/category/category.component";
import { CategoryEditDialogComponent } from "./components/dashboard/content/actual-questionbank/categories/category-edit-dialog/category-edit-dialog.component";
import { CategoryQuestionPositionEditDialogComponent } from "./components/dashboard/content/actual-questionbank/categories/category-question-position-edit-dialog/category-question-position-edit-dialog.component";
import { QuestiongroupsComponent } from "./components/dashboard/content/actual-questionbank/questiongroups/questiongroups.component";
import { QuestiongroupComponent } from "./components/dashboard/content/actual-questionbank/questiongroups/questiongroup/questiongroup.component";
import { QuestiongroupEditDialogComponent } from "./components/dashboard/content/actual-questionbank/questiongroups/questiongroup-edit-dialog/questiongroup-edit-dialog.component";
import { QuestionsComponent } from "./components/dashboard/content/actual-questionbank/questions/questions.component";
import { QuestionComponent } from "./components/dashboard/content/actual-questionbank/questions/question/question.component";
import { QuestionEditDialogComponent } from "./components/dashboard/content/actual-questionbank/questions/question-edit-dialog/question-edit-dialog.component";
import { QuestionCategoryAssignmentEditDialogComponent } from "./components/dashboard/content/actual-questionbank/questions/question-category-assignment-edit-dialog/question-category-assignment-edit-dialog.component";
import { FooterComponent } from "./components/dashboard/footer/footer.component";
import { RefreshCacheComponent } from "./components/dashboard/content/server/refresh-cache/refresh-cache.component";
import { StudentsComponent } from "./components/dashboard/content/users/students/students.component";
import { ChangelogComponent } from "./components/dashboard/content/actual-questionbank/changelog/changelog.component";
import { ConfirmationDialogComponent } from "./modules/confirmation-dialog/components/confirmation-dialog/confirmation-dialog.component";
import { SettingsComponent } from "./components/dashboard/content/admin-menu/settings/settings.component";
import { QuestionQuestiongroupAssignmentEditDialogComponent } from "./components/dashboard/content/actual-questionbank/questions/question-questiongroup-assignment-edit-dialog/question-questiongroup-assignment-edit-dialog.component";
import { ViewChangeDialogComponent } from "./components/dashboard/content/actual-questionbank/changelog/view-change-dialog/view-change-dialog.component";
import { ViewChangeComponent } from "./components/dashboard/content/actual-questionbank/changelog/view-change/view-change.component";
import { FeedbacksComponent } from "./components/dashboard/content/users/feedbacks/feedbacks.component";
import { FeedbackComponent } from "./components/dashboard/content/users/feedbacks/feedback/feedback.component";
import { StudentDetailsDialogComponent } from "./components/dashboard/content/users/students/student-details-dialog/student-details-dialog.component";
import { StudentFilloutDetailsComponent } from "./components/dashboard/content/users/students/student-details-dialog/student-fillout-details/student-fillout-details.component";
import { CreateGiftForUserDialogComponent } from "./components/dashboard/content/users/students/student-details-dialog/create-gift-dialog/create-gift-for-user-dialog.component";
import { UserNotificationsComponent } from "./components/dashboard/content/special/user-notifications/user-notifications.component";
import { CreateNotificationDialogComponent } from "./components/dashboard/content/special/user-notifications/create-notification-dialog/create-notification-dialog.component";
import { UserNotificationCardComponent } from "./components/dashboard/content/special/user-notifications/user-notification-card/user-notification-card.component";
import { GlobalGiftsComponent } from "./components/dashboard/content/special/global-gifts/global-gifts.component";
import { GlobalGiftCardComponent } from "./components/dashboard/content/special/global-gifts/global-gift-card/global-gift-card.component";
import { CreateGlobalGiftDialogComponent } from "./components/dashboard/content/special/global-gifts/create-global-gift-dialog/create-global-gift-dialog.component";
import { MaintenanceComponent } from "./components/dashboard/content/server/maintenance/maintenance.component";
import { StudentLoggingComponent } from "./components/dashboard/content/special/student-logging/student-logging.component";
import { PracticePathsComponent } from "./components/dashboard/content/practice/practice-paths/practice-paths.component";

import { PracticeIconsComponent } from "./components/dashboard/content/practice/practice-icons/practice-icons.component";
import { PracticeIconsEditorComponent } from "./components/dashboard/content/practice/practice-icons/practice-icons-editor/practice-icons-editor.component";
import { UnavailableOnProductionComponent } from "./components/dashboard/content/unavailable-on-production/unavailable-on-production.component";
import { FileUploadIndicatorDialogComponent } from "./components/misc/file-upload-indicator-dialog/file-upload-indicator-dialog.component";
import { IconButtonComponent } from "./components/misc/icon-button/icon-button.component";
import { PracticeCitiesComponent } from "./components/dashboard/content/practice/practice-cities/practice-cities.component";
import { PracticeCitiesEditorComponent } from "./components/dashboard/content/practice/practice-cities/practice-cities-editor/practice-cities-editor.component";
import { StudentDevicesComponent } from "./components/dashboard/content/users/students/student-details-dialog/student-devices/student-devices.component";
import { PracticeCityNodeEditorComponent } from "./components/dashboard/content/practice/practice-cities/practice-city-nodes/practice-city-node-editor/practice-city-node-editor.component";
import { PracticeCityCardComponent } from "./components/dashboard/content/practice/practice-cities/practice-city-card/practice-city-card.component";
import { PracticeCityNodesComponent } from "./components/dashboard/content/practice/practice-cities/practice-city-nodes/practice-city-nodes.component";
import { CityResponsiblesEditorComponent } from "./components/dashboard/content/practice/practice-cities/city-responsibles-editor/city-responsibles-editor.component";
import { ServerSelectorDialogComponent } from "./components/login/server-selector-dialog/server-selector-dialog.component";
import { PracticeVideoManagerDialogComponent } from "./components/dashboard/content/practice/practice-paths/practice-video-manager-dialog/practice-video-manager-dialog.component";
import { RawVideoTabComponent } from "./components/dashboard/content/practice/practice-paths/practice-video-manager-dialog/raw-video-tab/raw-video-tab.component";
import { AnonymizedVideosTabComponent } from "./components/dashboard/content/practice/practice-paths/practice-video-manager-dialog/anonymized-videos-tab/anonymized-videos-tab.component";
import { FinalVideosTabComponent } from "./components/dashboard/content/practice/practice-paths/practice-video-manager-dialog/final-videos-tab/final-videos-tab.component";
import { AnonymizerTaskLogsDialogComponent } from "./components/dashboard/content/practice/practice-paths/practice-video-manager-dialog/anonymized-videos-tab/anonymizer-task-logs-dialog/anonymizer-task-logs-dialog.component";
import { PracticePathCardComponent } from "./components/dashboard/content/practice/practice-paths/practice-path-card/practice-path-card.component";
import { AnonymizerTasksComponent } from "./components/dashboard/content/practice/anonymizer-tasks/anonymizer-tasks.component";
import { PackageDefinitionSelectorComponent } from "./components/misc/package-definition-selector/package-definition-selector.component";
import { VendorsComponent } from "./components/dashboard/content/users/vendors/vendors.component";
import { VendorDetailsDialogComponent } from "./components/dashboard/content/users/vendors/vendor-details-dialog/vendor-details-dialog.component";
import { CreateVendorGiftDialogComponent } from "./components/dashboard/content/users/vendors/vendor-details-dialog/components/create-vendor-gift-dialog/create-vendor-gift-dialog.component";
import { PersonalGiftsComponent } from "./components/dashboard/content/special/personal-gifts/personal-gifts.component";
import { CreatePersonalGiftViaEmailDialogComponent } from "./components/dashboard/content/special/personal-gifts/create-personal-gift-via-email-dialog/create-personal-gift-via-email-dialog.component";
import { StringListInputComponent } from "./components/misc/string-list-input/string-list-input.component";
import { VideojsPlayerComponent } from "./components/misc/videojs-player/videojs-player.component";
import { VrVideoFilePreviewDialogComponent } from "./components/misc/vr-video-file-preview-dialog/vr-video-file-preview-dialog.component";
import { CreateVendorDialogComponent } from "./components/dashboard/content/users/vendors/create-vendor-dialog/create-vendor-dialog.component";
import { PackageCodePipe } from "./pipes/package-code.pipe";
import { PracitceCityExamNumberNodeAssignmentEditorDialogComponent } from "./components/dashboard/content/practice/practice-cities/pracitce-city-exam-number-node-assignment-editor-dialog/pracitce-city-exam-number-node-assignment-editor-dialog.component";
import { AdminUsersComponent } from "./components/dashboard/content/admins/admin-users/admin-users.component";
import { PermissionsComponent } from "./components/dashboard/content/admins/permissions/permissions.component";
import { AdminRolesComponent } from "./components/dashboard/content/admins/admin-roles/admin-roles.component";
import { PermissionGroupsComponent } from "./components/dashboard/content/admins/permission-groups/permission-groups.component";
import { PermissionGroupEditorDialogComponent } from "./components/dashboard/content/admins/permission-groups/permission-group-editor-dialog/permission-group-editor-dialog.component";
import { PermissionEditorDialogComponent } from "./components/dashboard/content/admins/permissions/permission-editor-dialog/permission-editor-dialog.component";
import { AdminRoleEditorDialogComponent } from "./components/dashboard/content/admins/admin-roles/admin-role-editor-dialog/admin-role-editor-dialog.component";
import { PermissionSelectorComponent } from "./components/misc/permission-selector/permission-selector.component";
import { AdminUserEditorDialogComponent } from "./components/dashboard/content/admins/admin-users/admin-user-editor-dialog/admin-user-editor-dialog.component";
import { AdminRoleUpdatingDialogComponent } from "./components/dashboard/content/admins/admin-roles/admin-role-updating-dialog/admin-role-updating-dialog.component";
import { InstructorsComponent } from "./components/dashboard/content/users/instructors/instructors.component";
import { CreateInstructorDialogComponent } from "./components/dashboard/content/users/instructors/create-instructor-dialog/create-instructor-dialog.component";
import { InstructorCardComponent } from "./components/dashboard/content/users/instructors/instructor-card/instructor-card.component";
import {
  DEFAULT_TIMEOUT,
  TimeoutInterceptor,
} from "./services/common/http-timeout-interceptor";
import { PracticePathUploadDialogComponent } from "./components/dashboard/content/practice/practice-paths/practice-path-upload-dialog/practice-path-upload-dialog.component";
import { UploadFullVideoTabComponent } from "./components/dashboard/content/practice/practice-paths/practice-path-upload-dialog/tabs/upload-full-video-tab/upload-full-video-tab.component";
import { UploadSubVideosTabComponent } from "./components/dashboard/content/practice/practice-paths/practice-path-upload-dialog/tabs/upload-sub-videos-tab/upload-sub-videos-tab.component";
import { NotifierModule, NotifierService } from "angular-notifier";
import { NgxUiLoaderConfig, NgxUiLoaderModule } from "ngx-ui-loader";
import { PracticePathMainDataEditorPageComponent } from './components/dashboard/content/practice/practice-paths/practice-path-main-data-editor-page/practice-path-main-data-editor-page.component';
import { PracticePathMainDataEditorComponent } from './components/dashboard/content/practice/practice-paths/practice-path-main-data-editor-page/components/practice-path-main-data-editor/practice-path-main-data-editor.component';
import { PracticePathVideoPreviewWithUploadComponent } from './components/dashboard/content/practice/practice-paths/practice-path-main-data-editor-page/components/practice-path-video-preview-with-upload/practice-path-video-preview-with-upload.component';
import { SharedComponentsModule } from './modules/shared-module/shared-module.module';
import { PracticePathNodeTouchesEditorDialogComponent } from './components/dashboard/content/practice/practice-paths/practice-path-main-data-editor-page/components/practice-path-node-touches-editor-dialog/practice-path-node-touches-editor-dialog.component';
import { PracticePathVideoEditPageModule } from './modules/practice-path-video-editor-page/practice-path-video-editor-page.module';
import { PracticePathCreateNewComponent } from './components/dashboard/content/practice/practice-paths/practice-path-create-new/practice-path-create-new.component';
import { PracticePathFilterComponent } from './components/dashboard/content/practice/practice-paths/practice-path-filter/practice-path-filter.component';
import { PracticePathSortingSelectorComponent } from './components/dashboard/content/practice/practice-paths/practice-path-sorting-selector/practice-path-sorting-selector.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { ReleasedVideosTabComponent } from './components/dashboard/content/practice/practice-paths/practice-video-manager-dialog/released-videos-tab/released-videos-tab.component';
import { MrvegasTaskProgressDialogComponent } from './components/misc/mrvegas-task-progress-dialog/mrvegas-task-progress-dialog.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { PracticePathVideoCutterDialogComponent } from './components/dashboard/content/practice/practice-paths/practice-path-video-cutter-dialog/practice-path-video-cutter-dialog.component';
import { NarrationsDuringRecordingDialogComponent } from './components/dashboard/content/practice/practice-paths/narrations-during-recording-dialog/narrations-during-recording-dialog.component';
import { NarrationsDuringRecordingListTabComponent } from './components/dashboard/content/practice/practice-paths/narrations-during-recording-dialog/narrations-during-recording-list-tab/narrations-during-recording-list-tab.component';
import { NarrationsDuringRecordingUploadTabComponent } from './components/dashboard/content/practice/practice-paths/narrations-during-recording-dialog/narrations-during-recording-upload-tab/narrations-during-recording-upload-tab.component';
import { NarrationsDuringRecordingCardComponent } from './components/dashboard/content/practice/practice-paths/narrations-during-recording-dialog/narrations-during-recording-list-tab/narrations-during-recording-card/narrations-during-recording-card.component';
import { NewNarrationDuringRecordingItemComponent } from './components/dashboard/content/practice/practice-paths/narrations-during-recording-dialog/narrations-during-recording-upload-tab/new-narration-during-recording-item/new-narration-during-recording-item.component';
import { VendorBaseInformationsTabComponent } from './components/dashboard/content/users/vendors/vendor-details-dialog/components/vendor-base-informations-tab/vendor-base-informations-tab.component';
import { VendorPurchasesTabComponent } from './components/dashboard/content/users/vendors/vendor-details-dialog/components/vendor-purchases-tab/vendor-purchases-tab.component';
import { VendorGiftsTabComponent } from './components/dashboard/content/users/vendors/vendor-details-dialog/components/vendor-gifts-tab/vendor-gifts-tab.component';
import { NarrationPlayerDialogComponent } from './components/dashboard/content/practice/practice-paths/narrations-during-recording-dialog/narrations-during-recording-list-tab/narration-player-dialog/narration-player-dialog.component';
import { ModifyVendorDialogComponent } from './components/dashboard/content/users/vendors/modify-vendor-dialog/modify-vendor-dialog.component';
import { VendorDataFormComponent } from './components/dashboard/content/users/vendors/vendor-data-form/vendor-data-form.component';
import { VendorDocumentsTabComponent } from './components/dashboard/content/users/vendors/vendor-details-dialog/components/vendor-documents-tab/vendor-documents-tab.component';
import { AddVendorDocumentsDialogComponent } from './components/dashboard/content/users/vendors/vendor-details-dialog/components/add-vendor-documents-dialog/add-vendor-documents-dialog.component';
import { FileSizePipe } from './pipes/file-size.pipe';
import { VendorPackageGroupInfoComponent } from './components/dashboard/content/users/vendors/vendor-details-dialog/components/vendor-package-group-info/vendor-package-group-info.component';
import { PracticalModuleLogsModule } from './modules/practical-module-logs/practical-module-logs.module';
import { ConfirmationDialogWithReasonComponent } from './components/dashboard/content/practice/practice-paths/practice-video-manager-dialog/confirmation-dialog-with-reason/confirmation-dialog-with-reason.component';
import { PracticalModuleLogsPageComponent } from './components/dashboard/content/practice/practical-module-logs-page/practical-module-logs-page.component';

const loaderConfig: NgxUiLoaderConfig = {
  bgsColor: "#434bb2",
  bgsOpacity: 0.8,
  bgsPosition: "bottom-right",
  bgsSize: 37,
  bgsType: "ball-spin-clockwise",
  blur: 4,
  delay: 0,
  fastFadeOut: true,
  fgsColor: "white",
  fgsPosition: "center-center",
  fgsSize: 50,
  fgsType: "ball-spin-clockwise",
  gap: 24,
  logoPosition: "center-center",
  logoSize: 120,
  logoUrl: "",
  masterLoaderId: "master",
  overlayBorderRadius: "0",
  overlayColor: "rgba(40, 40, 40, 0.8)",
  pbColor: "red",
  pbDirection: "ltr",
  pbThickness: 3,
  hasProgressBar: false,
  text: "",
  textColor: "#FFFFFF",
  textPosition: "center-center",
  maxTime: -1,
  minTime: 300,
};

@NgModule({
  declarations: [
    // Pipes
    MsToTimeStringPipe,
    PractiseTimePipe,
    PrefixWithDotsPipe,
    TypeofPipe,
    SafePipe,
    // Default components
    AppComponent,
    // Custom components
    AnonymizerTasksTableComponent,
    DashboardComponent,
    HeaderComponent,
    NavigationComponent,
    NavigationComponent,
    FooterComponent,
    ContentComponent,
    WelcomeComponent,
    StatisticsComponent,
    CategoriesComponent,
    CategoryComponent,
    CategoryEditDialogComponent,
    LoginComponent,
    PageNotFoundComponent,
    QuestionsComponent,
    QuestionComponent,
    QuestionEditDialogComponent,
    QuestiongroupsComponent,
    QuestiongroupComponent,
    QuestiongroupEditDialogComponent,
    RefreshCacheComponent,
    StudentsComponent,
    CategoryQuestionPositionEditDialogComponent,
    QuestionCategoryAssignmentEditDialogComponent,
    ChangelogComponent,
    ConfirmationDialogComponent,
    SettingsComponent,
    QuestionQuestiongroupAssignmentEditDialogComponent,
    ViewChangeDialogComponent,
    ViewChangeComponent,
    FeedbacksComponent,
    FeedbackComponent,
    StudentDetailsDialogComponent,
    StudentFilloutDetailsComponent,
    CreateGiftForUserDialogComponent,
    UserNotificationsComponent,
    CreateNotificationDialogComponent,
    UserNotificationCardComponent,
    GlobalGiftsComponent,
    GlobalGiftCardComponent,
    CreateGlobalGiftDialogComponent,
    MaintenanceComponent,
    StudentLoggingComponent,
    PracticePathsComponent,
    PracticeIconsComponent,
    PracticeIconsEditorComponent,
    UnavailableOnProductionComponent,
    FileUploadIndicatorDialogComponent,
    IconButtonComponent,
    PracticeCitiesComponent,
    PracticeCitiesEditorComponent,
    StudentDevicesComponent,
    PracticeCityNodeEditorComponent,
    PracticeCityCardComponent,
    PracticeCityNodesComponent,
    CityResponsiblesEditorComponent,
    ServerSelectorDialogComponent,
    PracticeVideoManagerDialogComponent,
    RawVideoTabComponent,
    AnonymizedVideosTabComponent,
    FinalVideosTabComponent,
    AnonymizerTaskLogsDialogComponent,
    PracticePathCardComponent,
    AnonymizerTasksComponent,
    PackageDefinitionSelectorComponent,
    VendorsComponent,
    VendorDetailsDialogComponent,
    CreateVendorGiftDialogComponent,
    PersonalGiftsComponent,
    CreatePersonalGiftViaEmailDialogComponent,
    StringListInputComponent,
    VideojsPlayerComponent,
    VrVideoFilePreviewDialogComponent,
    CreateVendorDialogComponent,
    PackageCodePipe,
    PracitceCityExamNumberNodeAssignmentEditorDialogComponent,
    AdminUsersComponent,
    PermissionsComponent,
    AdminRolesComponent,
    PermissionGroupsComponent,
    PermissionGroupEditorDialogComponent,
    PermissionEditorDialogComponent,
    AdminRoleEditorDialogComponent,
    PermissionSelectorComponent,
    AdminUserEditorDialogComponent,
    AdminRoleUpdatingDialogComponent,
    InstructorsComponent,
    CreateInstructorDialogComponent,
    InstructorCardComponent,
    PracticePathUploadDialogComponent,
    UploadFullVideoTabComponent,
    UploadSubVideosTabComponent,
    PracticePathMainDataEditorPageComponent,
    PracticePathMainDataEditorComponent,
    PracticePathVideoPreviewWithUploadComponent,
    PracticePathNodeTouchesEditorDialogComponent,
    PracticePathCreateNewComponent,
    PracticePathFilterComponent,
    PracticePathSortingSelectorComponent,
    ReleasedVideosTabComponent,
    MrvegasTaskProgressDialogComponent,
    PlaygroundComponent,
    PracticePathVideoCutterDialogComponent,
    VendorBaseInformationsTabComponent,
    VendorPurchasesTabComponent,
    VendorGiftsTabComponent,
    NarrationsDuringRecordingDialogComponent,
    NarrationsDuringRecordingListTabComponent,
    NarrationsDuringRecordingUploadTabComponent,
    NarrationsDuringRecordingCardComponent,
    NewNarrationDuringRecordingItemComponent,
    NarrationPlayerDialogComponent,
    ModifyVendorDialogComponent,
    VendorDataFormComponent,
    VendorDocumentsTabComponent,
    AddVendorDocumentsDialogComponent,
    FileSizePipe,
    VendorPackageGroupInfoComponent,
    ConfirmationDialogWithReasonComponent,
    PracticalModuleLogsPageComponent
  ],
  imports: [
    // Built-in components and modules
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    // Angular materials
    BrowserAnimationsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    NgxJsonViewerModule,
    MrvegasMetadataViewerModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    ClipboardModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    PracticePathVideoEditPageModule,
    PracticeCityZoneEditorModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    SharedComponentsModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    NgxUiLoaderModule.forRoot(loaderConfig),
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 51,
      outerStrokeWidth: 2,
      innerStrokeWidth: 2,
      outerStrokeColor: "#808080",
      innerStrokeColor: "#e7e8ea",
      animation:false
    }),
    MatSlideToggleModule,
    MatSnackBarModule,
    DragDropModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    // 3rd party modules
    NotifierModule.withConfig({
      behaviour: {
        autoHide: 8000,
        stacking: 8,
        onMouseover: "pauseAutoHide",
        showDismissButton: true,
      },
    }),
    NgxEchartsModule.forRoot({
      echarts: () => import("echarts"),
    }),
    // Custom modules
    LoggerModule,
    CommentsOnEntityModule,
    PracticalModuleLogsModule
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: "hu-HU" },
    { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
    { provide: DEFAULT_TIMEOUT, useValue: 100000 }
  ],
  exports: [MsToTimeStringPipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
