import { CustomValidators } from "src/app/classes/custom-validators";
import { PracticePathUtil } from "../../../../services/practice-path-util";
import { CriticalPoint, CriticalPointAssignment, CriticalPointPositionInPath, PathItem, StopPoint } from "./../../../../classes/model/practice-path";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Injector,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NotifierService } from "angular-notifier";
import { GeoHelper } from "../../GeoHelper";
import { BehaviorSubject, Subscription } from "rxjs";
import { OnDestroy } from "@angular/core";
import { VideoPlayerController } from "src/app/modules/shared-module/components/video-player/video-player.controller";
import { OtherPracticePathsToCriticalPointAssignedToDialogComponent, OtherPracticePathsToCriticalPointAssignedToDialogData } from "./other-practice-paths-to-critical-point-assigned-to-dialog/other-practice-paths-to-critical-point-assigned-to-dialog.component";
import { PracticePathGlobalVideoEditorPageService } from "../../services/practice-path-global-video-editor-page.service";
import { CriticalPointService } from "src/app/services/critical-point-service";
import { Permission } from "src/app/classes/model/permissions";
import { PermissionService } from "src/app/services/common/permission.service";

// A kritikus pont szerkesztőnek az eredménye
export type CriticalPointEditorResult = {
  title: string;
  description: string;
  isLocal: boolean;
  hasDirectionalAngle: boolean;
  criticalPointCoordinate: { latitude: number; longitude: number };
  anchorPoint: { latitude: number; longitude: number };
  directionAngle?: {
    point: { latitude: number; longitude: number };
    angle: number;
  };
  stopPoints: {
    stopPointUuid: string;
    focusSettings?: { x: number; y: number; radius: number };
    stopTimeInMs: number;
    isActive: boolean;
  }[];
};

@Component({
  selector: "app-critical-point-editor-dialog",
  templateUrl: "./critical-point-editor-dialog.component.html",
  styleUrls: ["./critical-point-editor-dialog.component.scss"],
})
export class CriticalPointEditorDialogComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild("descriptionTextArea", { read: ElementRef })
  descriptionTextArea: ElementRef;

  readonly maxTitleLength = 28;
  // A kritikus pontot ekkora sugarú körön belül lehet mozgatni a lerakásától
  readonly criticalPointAnchorRadius = 100;

  // Kritikus ponthoz tartozó adatok
  formGroupForCriticalPoint!: FormGroup;

  videoPlayerPositionSubscription: Subscription;
  previewVideoPlayerController!: VideoPlayerController;

  // Fókusz pont szerkesztés aktív-e jelenleg (ha igen, akkor a mouse eventeket lekezeljük)
  isFocusEditState: boolean = false;

  // Jelenlegi irányszög
  currentDirectionalAngle?: {
    angle: number;
    point: { latitude: number; longitude: number };
  };

  isFocusedFormControllers: FormControl[] = [];
  selectedStopPointUuid = "";

  // Ha a stop pontok változnak ezen keresztül értesítsjük a térképet
  stopPointsAsObservable: BehaviorSubject<StopPoint[]> = new BehaviorSubject(
    []
  );

  criticalPointPositionInPaths:Array<CriticalPointPositionInPath>|null = null;
  criticalPointPositionInPathsCalculationHasError:boolean = false;

  private readonly stopPointNearTimeThresholdInMs:number = 500;
  private readonly disableStopPointPlacingInBeginningInMs:number = 1000;

  constructor(
    public dialog: MatDialogRef<CriticalPointEditorDialogComponent>,
    private dialogService:MatDialog,
    private notifierService: NotifierService,
    private practicePathGlobalVideoEditorPageService:PracticePathGlobalVideoEditorPageService,
    private practiceEditorService: PracticePathUtil,
    private viewContainerRef:ViewContainerRef,
    private injector:Injector,
    private criticalPointService:CriticalPointService,
    private permissionService:PermissionService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      // az összes többi kritikus pont
      // megjelenítjük,hogy a jelenlegi kritikus pontot a többihez képest mozgathassuk ha kell (ne legyenek egymáson)
      allOtherCriticalPoints: CriticalPoint[];
      criticalPoint: CriticalPoint;
      videoUrl: string;
      defaultSelectedStopPointIndex: number;
      geoTaggedFrames: PathItem[];
      stopPoints: StopPoint[];
      showPathMarkers: boolean; // Sárga pöttyöket megjelenítsük-e ,vagy csak a polyline-t
      actualPracticePathUuid:string;
    }
  ) {
    // Default kiválasztott stop point beállítása
    if (
      this.data.stopPoints.length != 0 &&
      this.data.defaultSelectedStopPointIndex != undefined
    ) {
      this.selectedStopPointUuid =
        this.data.stopPoints[
          this.data.defaultSelectedStopPointIndex
        ].stopPointUuid;
    }

    // Ha van irányszög az init adatban beállítjuk!
    if (this.data.criticalPoint.directionalAngle != undefined) {
      this.currentDirectionalAngle = {
        angle: this.data.criticalPoint.directionalAngle,
        point: this.data.criticalPoint.directionalAnglePoint,
      };
    }
    // Az aktív stop pontokat frissítjük, ezt kapja meg a directional angle
    // ha a stop pontok aktivitása változik azt ezen a BehaviourSubject-en megkapja
    this.stopPointsAsObservable.next(this.getActiveStopPoints());
  }
  ngOnDestroy(): void {
    this.stopPointsAsObservable.unsubscribe();
    this.formControllerChangeSubscriptions.forEach((s) => s.unsubscribe());
    this.formControllerChangeSubscriptions = [];
    this.isFocusedFormControllers = [];

    this.videoPlayerPositionSubscription?.unsubscribe();
    this.previewVideoPlayerController?.destroy();
  }

  ngOnInit(): void {
    this.initFormGroupForCriticalPoint();
    this.initIsFocusedFormControllers();

    // Ha nem létezik még megállási pont ehhez a kritikus ponthoz
    // akkor próbáljunk meg becsülni. Lehet, hogy becslés után se lesz megállási pont
    // ha az adott útvonaltól távol esik
    if (this.data.stopPoints.length == 0) {
      this.onTapStopPointsReset();
      if (this.data.stopPoints.length != 0) {
        // Ha a resetelés hatására lett legalább 1, akkor állítsunk be default indexet
        // hogy a videót az első becslésre tekerjük
        this.data.defaultSelectedStopPointIndex = 0;
      }
    }

    this.previewVideoPlayerController = new VideoPlayerController(
      this.data.videoUrl,
      this.getInitialVideoPosition()
    );

    // Check if the critical point has a uuid to determine if we adding a new critical point or
    // editing an existing one
    if(this.data.criticalPoint.uuid) {
      // Init the other practice path to critical point is assigned to fields only if we are
      // in editing mode
      this.initOtherPracticePathsToAssignedTo();
    }
  }

  ngAfterViewInit(): void {
    this.addScrollbarCheckForDescriptionFC();
  }

  /**
   * Inits the `otherPracticePathsToAssignedTo` array with practice paths that has the actually editet critical
   * point assigned and differs from the actual edited practice path. If the data fetching was unsuccessful
   * the `otherPracticePathsLoadingHasError` to `true`.
   */
  protected async initOtherPracticePathsToAssignedTo():Promise<void> {
    // Set the error flag to it's default state
    this.criticalPointPositionInPathsCalculationHasError = false;

    try {
      // Fetch the critical point's position in the practice paths
      this.criticalPointPositionInPaths = await this.criticalPointService.fetchCalculatedPositionOfCriticalPointInAssignedPaths(
       this.practicePathGlobalVideoEditorPageService.city.uuid,
       this.data.criticalPoint.uuid
      );
      // Filter out the actually edited practice path
      this.criticalPointPositionInPaths = this.criticalPointPositionInPaths.filter(
        (criticalPointPositionInPath:CriticalPointPositionInPath) => {
          return criticalPointPositionInPath.practicePathUuid !== this.data.actualPracticePathUuid
        }
      );
    } catch(error:any) {
      console.error(error);
      // Set the error flag that an error happened during the fetching
      this.criticalPointPositionInPathsCalculationHasError = true;
    }
  }

  addScrollbarCheckForDescriptionFC() {
    // scrollbar check validators utólag
    // mivel szükség van a text area view-ra ezért afterViewInit-ben kell hozzáadnunk a validatort
    // a text area még ngOnInit-ben nem létezik

    const descFC = this.formGroupForCriticalPoint.get("descriptionFC");
    descFC.addValidators(
      CustomValidators.noScrollbar(this.descriptionTextArea.nativeElement)
    );

    this.formGroupForCriticalPoint
      .get("descriptionFC")
      .valueChanges.subscribe(() => {
        // Maximum 8 sort engedélyezünk, ha több, akkor töröljük az utoljára leütött karaktereket
        const hasScrollbarErros = CustomValidators.noScrollbar(
          this.descriptionTextArea.nativeElement
        )(descFC);
        if (hasScrollbarErros != null) {
          descFC.setValue(descFC.value.slice(0, -1));
        }
      });
  }

  formControllerChangeSubscriptions: Subscription[] = [];
  // A fókusz checkboxokat inicializálja
  // Ha például resetelődnek a megállási pontok,vagy törlődik egy, stb..
  // Ha már egy hozzáadott megállási pontnak egy attribútuma változik akkor NEM kell meghívni
  initIsFocusedFormControllers() {
    this.isFocusedFormControllers = [];

    // Az előzől feliratkozásokat töröljük ha voltak
    this.formControllerChangeSubscriptions.forEach((f) => f.unsubscribe());
    this.formControllerChangeSubscriptions = [];

    for (let i = 0; i < this.data.stopPoints.length; i++) {
      const newFc = new FormControl(
        this.data.stopPoints[i].focusSettings != undefined
      );
      this.isFocusedFormControllers.push(newFc);

      this.formControllerChangeSubscriptions.push(
        newFc.valueChanges.subscribe((newValue) => {
          if (newValue) {
            this.isFocusEditState = true;
            if (
              this.selectedStopPointUuid !=
              this.data.stopPoints[i].stopPointUuid
            ) {
              this.selectedStopPointUuid =
                this.data.stopPoints[i].stopPointUuid;
            }

            this.data.stopPoints[i].focusSettings ??= {
              radius: 0.3,
              x: 0.5,
              y: 0.5,
            };
          } else {
            if (
              this.selectedStopPointUuid ==
              this.data.stopPoints[i].stopPointUuid
            ) {
              this.isFocusEditState = false;
            }
            this.data.stopPoints[i].focusSettings = undefined;
          }
        })
      );
    }
  }

  getActiveStopPoints = () => this.data.stopPoints.filter((sp) => sp.isActive);

  // A kritikus pontnak az adatait tároló form group-ot inicializálja
  initFormGroupForCriticalPoint() {
    this.formGroupForCriticalPoint = new FormGroup({
      titleFC: new FormControl(this.data.criticalPoint.title ?? "", [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(this.maxTitleLength),
      ]),
      descriptionFC: new FormControl(
        this.data.criticalPoint.description ?? "",
        [
          Validators.required,
          Validators.minLength(1),
          CustomValidators.maximalLineCount(8),
        ]
      ),
      hasDirectionAngleFC: new FormControl(
        /* this.data.criticalPoint.directionalAngle != null */ false,
        []
      ),
      isLocalFC: new FormControl(this.data.criticalPoint.isLocal, []),
    });
  }

  // Ha nem találja undefined-al tér vissza
  // egyébként pedig az éppen kiválasztott megállási ponttal
  public getSelectedStopPoint() : StopPoint|undefined{
    return this.data.stopPoints.find(
      (value) => value.stopPointUuid == this.selectedStopPointUuid
    );
  }

  // be vagy kipipáltak egy isActive checkboxot
  onChangeStopPointActiveCheckbox() {
    this.stopPointsAsObservable.next(this.data.stopPoints);
  }

  // Megnézzük, hogy az adott pontra eltekerhet-e
  // akkor tekerhet el, ha az a kritikus pont körén belül marad (anchorPoint középpontú körön belül)
  private isSeekAllowed(seekTo: number): boolean {
    const geoTagged =
      this.practiceEditorService.getClosestGeoTaggedFrameToVideoPosition(
        this.data.geoTaggedFrames,
        seekTo
      );
    const distFromAnchor = GeoHelper.distance(
      geoTagged.position.latitude,
      geoTagged.position.longitude,
      this.data.criticalPoint.anchorPoint.latitude,
      this.data.criticalPoint.anchorPoint.longitude
    );
    return distFromAnchor < this.criticalPointAnchorRadius;
  }

  onTapStopPointsReset() {
    const newStopPoints = this.practiceEditorService.estimateStopPoints(
      this.data.geoTaggedFrames,
      this.data.criticalPoint.coordinate
    );
    this.data.stopPoints = newStopPoints.map((sp) => {
      return {
        stopPointUuid: Math.random().toString(), // TODO CHANge,
        stopTimeInMs: sp.stopTimeInMs,
        isActive: false,
      };
    });
    this.stopPointsAsObservable.next(this.data.stopPoints);
    if (this.data.stopPoints.length != 0) {
      this.selectedStopPointUuid = this.data.stopPoints[0].stopPointUuid;
      this.previewVideoPlayerController?.seekTo(
        this.data.stopPoints[0].stopTimeInMs
      );
    }

    this.initIsFocusedFormControllers();
  }

  getInitialVideoPosition(){
    const initialVideoPosition =
    this.data.defaultSelectedStopPointIndex == undefined
      ? 0
      : this.data.stopPoints[this.data.defaultSelectedStopPointIndex]
          .stopTimeInMs;
    return initialVideoPosition;
  }

  // Amikor a video player inicializálódott. (A video-player hívja, mint callbacket)
  videoPlayerInited() {
    const initialVideoPosition = this.getInitialVideoPosition();

    let lastVideoPosition = initialVideoPosition;
    this.previewVideoPlayerController._currentPositionInMs$.next(
      initialVideoPosition
    );

    // Ha a videó pozíció változik, akkor frissítsük a jelenleg kiválasztott megállási pontot
    let lastWarningTimestamp = 0;

    this.videoPlayerPositionSubscription = this.previewVideoPlayerController
      .getCurrentPositionInMs$()
      .subscribe((currentPos) => {
        const selectedStopPoint = this.getSelectedStopPoint();
        // Ha nincs kiválasztva megállási pont (mert például nem létezik egy se)
        // akkor nem kell semmit updatelni
        if (!selectedStopPoint) return;

        if (this.isSeekAllowed(currentPos)) {
          selectedStopPoint.stopTimeInMs = currentPos;

          // If the stop point was active
          if(selectedStopPoint.isActive && this.isStoppingTimeInvalid(selectedStopPoint)) {
            selectedStopPoint.isActive = false;
          }

          lastVideoPosition = currentPos;
        } else {
          // Ha már legalább 6 másodperce volt az utolsó figyelmeztetés
          if (lastWarningTimestamp < Date.now().valueOf() - 6000) {
            this.notifierService.notify(
              "error",
              "Nem tekerhetsz ki a zöld körön kívülre!"
            );
            lastWarningTimestamp = Date.now().valueOf();
          }
          this.previewVideoPlayerController.seekTo(lastVideoPosition);
        }

        this.stopPointsAsObservable.next(this.data.stopPoints);
      });
  }

  // Az input alapján összeállítja a kritikus pontot
  // amivel a dialógus visszatérhet
  private composeCriticalPointFromInputs(): CriticalPointEditorResult {
    const hasDirectionAngle = this.formGroupForCriticalPoint.get(
      "hasDirectionAngleFC"
    )!.value;
    return {
      description: this.formGroupForCriticalPoint.get("descriptionFC")!.value,
      title: this.formGroupForCriticalPoint.get("titleFC")!.value,
      directionAngle: hasDirectionAngle
        ? this.currentDirectionalAngle
        : undefined,
      hasDirectionalAngle: hasDirectionAngle,
      isLocal: this.formGroupForCriticalPoint.get("isLocalFC")!.value,
      stopPoints: this.data.stopPoints,
      criticalPointCoordinate: this.data.criticalPoint.coordinate,
      anchorPoint: this.data.criticalPoint.anchorPoint,
    };
  }

  // Kritikus pont mentése
  onTapSaveButton() {
    // Állítsuk össze az eredmény objektumot
    const composedPointFromInputs = this.composeCriticalPointFromInputs();
    this.dialog.close(composedPointFromInputs);
  }

  // Ha nem volt fókusz mód és bekapcsoljuk akkor az edit alapból legyen true!
  onTapEditFocus() {
    this.isFocusEditState = !this.isFocusEditState;
  }

  // Bezárja a dialógust
  onTapCancelButton() {
    this.dialog.close();
  }

  // A stopPointUuid-ra kattintott (A listában a span text)
  onTapStopPoint(stopPointUuid: string) {
    this.selectedStopPointUuid = stopPointUuid;
    const point = this.data.stopPoints.find(
      (value) => value.stopPointUuid == stopPointUuid
    );
    this.previewVideoPlayerController.seekTo(point?.stopTimeInMs ?? 0);

    // Állítsuk be a fókusz checkboxot
    this.isFocusEditState = false;
  }

  // Görgő segítségével a kritikus pont sugarának változtatása
  @HostListener("pinch", ["$event"])
  @HostListener("wheel", ["$event"])
  onWheelScroll(evento: WheelEvent) {
    if (this.isFocusEditState == false) return;

    const scaleExtent = evento.deltaY / 2500;
    this.getSelectedStopPoint()!.focusSettings!.radius += scaleExtent;

    this.getSelectedStopPoint()!.focusSettings!.radius = Math.max(
      0.2,
      Math.min(0.7, this.getSelectedStopPoint()!.focusSettings!.radius)
    );
  }

  // Kritikus pont mozgatása egérrel
  @HostListener("document:mousemove", ["$event"])
  onMouseMove(e: any) {
    if (this.isFocusEditState == false) return;
    // A focus overlay-en belül a canvas azonosítója 'focus'
    if (e.target.id == "focus") {
      this.getSelectedStopPoint()!.focusSettings!.x =
        e.layerX / e.target.clientWidth;
      this.getSelectedStopPoint()!.focusSettings!.y =
        e.layerY / e.target.clientHeight;
    }
  }

  // A fókusz pont pozícióját véglegesíteni egy bal klikkel lehet
  // ez a listener kezeli le a canvas-on a kattintást (aminek az id-ja focus)
  // és a focus szerkesztést kikapcsolja (ezzel véglegesítve azt a pozíciót ahol az egér éppen van)
  @HostListener("click", ["$event"])
  onClick(e: any) {
    if (this.isFocusEditState == false) return;

    if (e.target.id == "focus") {
      this.isFocusEditState = false;
    }
  }

  protected openOtherPracticePathsToCriticalPointAssignedToDialog():void {
    this.dialogService.open<
      OtherPracticePathsToCriticalPointAssignedToDialogComponent,
      OtherPracticePathsToCriticalPointAssignedToDialogData
    >(
      OtherPracticePathsToCriticalPointAssignedToDialogComponent,
      {
        data: {
          criticalPointPositionInPaths: this.criticalPointPositionInPaths
        },
        viewContainerRef: this.viewContainerRef,
        injector: this.injector
      }
    );
  }

  protected hasUserPracticePathWritePermission():boolean {
    return this.permissionService.isLoggedUserHasPermission(Permission.PracticePathWrite);
  }

  /**
   * Determines that the target stop point's checkbox is disabled. The checkbox should be disabled when
   * the stop point's stopping time is invalid and the checkbox is not checked.
   *
   * @param targetStopPoint the target stop point
   *
   * @returns true if the checkbox should be disabled,
   */
  protected isStoppingPointCheckDisabled(targetStopPoint:StopPoint):boolean {
    return targetStopPoint.isActive === false && this.isStoppingTimeInvalid(targetStopPoint);
  }

  /**
   * Determines if the target stop point's stopping time is valid. The time is invalid if the stop point's
   * stopping time is too close to the beginning of the video or too close to an other stop point's stopping
   * time.
   *
   * @param targetStopPoint the target stop point
   *
   * @returns true if the stopping time is invalid, false otherwise
   */
  private isStoppingTimeInvalid(targetStopPoint:StopPoint):boolean {
    return this.hasAnotherStoppingPointNearInTime(targetStopPoint) ||
    targetStopPoint.stopTimeInMs < this.disableStopPointPlacingInBeginningInMs;
  }

  /**
   * Gets the reason for the disabled stop point checkbox.
   *
   * @param targetStopPoint the target stop point
   *
   * @returns the reason if some of the conditions met of the disabling, null otherwise
   */
  protected getStopPointCheckDisabledMessage(targetStopPoint:StopPoint):string|null {
    if(targetStopPoint.stopTimeInMs < this.disableStopPointPlacingInBeginningInMs) {
      return "A megállási pont túl közel van a videó elejéhez!";
    }

    const nearStopPoint = this.getAnotherStoppingPointNearInTime(targetStopPoint);
    if(nearStopPoint) {
      // Get the index if the critical point assignment
      const criticalPointAssignmentIndex: number = this.practicePathGlobalVideoEditorPageService.criticalPointAssignments$.findIndex(
        (criticalPointAssignment: CriticalPointAssignment) => {
          return criticalPointAssignment.uuid === nearStopPoint.criticalPointAssignmentUuid;
        }
      );
      
      const criticalPointTitle: string = this.practicePathGlobalVideoEditorPageService.city.criticalPoints.find(
        (criticalPoint: CriticalPoint) => {
          return criticalPoint.uuid === nearStopPoint.criticalPointUuid;
        }
      )?.title ?? "Hiba a kritikus pont szöveg meghatározása közben";

      return `Túl közeli megálláspont: ${criticalPointAssignmentIndex + 1}. ${criticalPointTitle}`;
    }

    return null;
  }

  /**
   * Determines that the target stop point's stopping time is near to an other stop point's stopping time.
   *
   * @param targetStopPoint the target stop point
   *
   * @returns true if there is an other stopping time near, false otherwise
   */
  private hasAnotherStoppingPointNearInTime(targetStopPoint: StopPoint): boolean {
    return this.getAnotherStoppingPointNearInTime(targetStopPoint) != null;
  }

  /**
   * Gets the first critical point assignment's stop point which is too close to the target stop point' stoping time.
   * If no such stop point exists, `null` returned.
   *
   * @param targetStopPoint the target stop point
   *
   * @returns information object about the close stop point if one exists, `null` otherwise
   */
  private getAnotherStoppingPointNearInTime(
    targetStopPoint:StopPoint
  ): { criticalPointAssignmentUuid: string, criticalPointUuid :string, stopPointuUuid: string } | null {
    // Iterate over all critical point assingments of the path
    for(const criticalPointAssignment of this.practicePathGlobalVideoEditorPageService.criticalPointAssignments$) {
      // Iterate over the critical point's stopping points
      for(const stopPoint of criticalPointAssignment.stopPoints) {
        // If the stopping point is the same as the target stopping point or
        // the stopping point is inactive
        if(stopPoint.stopPointUuid === targetStopPoint.stopPointUuid || stopPoint.isActive === false) {
          // There is nothing to do
          continue;
        }

        // Calculate the time difference between the stop points
        const timeDifference:number = Math.abs(stopPoint.stopTimeInMs - targetStopPoint.stopTimeInMs);
        // If the time difference is less than the threshold
        if(timeDifference < this.stopPointNearTimeThresholdInMs) {
          // Return the found stop point info, because then there is a stopping point near
          return {
            criticalPointAssignmentUuid: criticalPointAssignment.uuid,
            criticalPointUuid: criticalPointAssignment.criticalPointUuid,
            stopPointuUuid: stopPoint.stopPointUuid
          };
        }
      }
    }

    // If no stopping point found near, return null
    return null;
  }
}
