import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogMode } from 'src/app/classes/misc';
import { PracticeIcon } from 'src/app/classes/model/practice-icons';
import { PracticeIconService } from 'src/app/services/practice-icon.service';
import { ConfirmationDialogService } from 'src/app/modules/confirmation-dialog/services/confirmation-dialog.service';
import { CustomAsyncValidators } from 'src/app/classes/custom-validators';

@Component({
  selector: 'app-practice-icons-editor',
  templateUrl: './practice-icons-editor.component.html',
  styleUrls: ['./practice-icons-editor.component.scss']
})
export class PracticeIconsEditorComponent implements OnInit {
  DialogMode = DialogMode;
  dialogTitle:string;

  nameFC:FormControl;
  iconFC:FormControl;
  practiceIconFG:FormGroup;

  acceptableFileTypes:ReadonlyArray<string> = [ "image/png" ];

  constructor(
    private dialogRef:MatDialogRef<PracticeIconsEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data:{ editorMode:DialogMode , practiceIcon:PracticeIcon|null },
    private pracitceIconService:PracticeIconService,
    private snackBar:MatSnackBar,
    private confirmationDialogService:ConfirmationDialogService
  ) { }

  ngOnInit(): void {
    this.dialogTitle = "Útvonal ikon " + ( this.data.editorMode === DialogMode.Add ? "felvétele" : "módosítása" );

    this.nameFC = new FormControl(this.data.practiceIcon?.name ?? "", [ Validators.required ]);
    this.iconFC = new FormControl(this.data.practiceIcon?.iconUrl ?? null, [ Validators.required ], [ CustomAsyncValidators.fileImageAspectRatio(1, 1) ]);;

    this.practiceIconFG = new FormGroup({
      nameFC: this.nameFC,
      iconFC: this.iconFC
    });
  }


  public async handleAddButtonClick():Promise<void> {
    const newPracticeIcon:Partial<PracticeIcon> = {
      name: this.nameFC.value
    };

    try {
      await this.pracitceIconService.addPracticeIcon(newPracticeIcon, this.iconFC.value as File);
      this.snackBar.open("Sikeres útvonal ikon felvétel.", "Bezár", { duration: 3000, panelClass: [ "mat-snackbar-success" ] });
      this.dialogRef.close();
    } catch(error:any) {}
  }

  public async handleDeleteButtonClick():Promise<void> {
    this.confirmationDialogService.open(
      "Vizsgaútvonal ikon törlése",
      "Biztos benne?",
      async () => {
        try {
          await this.pracitceIconService.deletePracticeIcon(this.data.practiceIcon.uuid);
          this.snackBar.open("Sikeres útvonal ikon törlés.", "Bezár", { duration: 3000, panelClass: [ "mat-snackbar-success" ] });
          this.dialogRef.close();
        } catch(error:any) {}
      }
    );
  }

  public async handleEditButtonClick():Promise<void> {
    const modifiedPracticeIcon:PracticeIcon = {
      uuid: this.data.practiceIcon.uuid,
      name: this.nameFC.value,
      iconUrl: this.data.practiceIcon.iconUrl
    };

    const iconMedia:File|null = this.iconFC.value instanceof File ? this.iconFC.value : null;

    try {
      await this.pracitceIconService.modifyPracticeIcon(modifiedPracticeIcon, iconMedia);
      this.snackBar.open("Sikeres útvonal ikon felvétel.", "Bezár", { duration: 3000, panelClass: [ "mat-snackbar-success" ] });
      this.dialogRef.close();
    } catch(error:any) {}
  }


}
