import { PracticePathGlobalVideoEditorPageService, PracticePathVideoType } from "../../services/practice-path-global-video-editor-page.service";
import { Component, OnInit } from "@angular/core";
import { NarrationsDuringRecordingDialogComponent } from "src/app/components/dashboard/content/practice/practice-paths/narrations-during-recording-dialog/narrations-during-recording-dialog.component";
import { MatDialog } from "@angular/material/dialog";

export type StatisticForPath = {
  displayedName: string;
  value: number;
};

@Component({
  selector: "app-other-info-tab",
  templateUrl: "./other-info-tab.component.html",
  styleUrls: ["./other-info-tab.component.scss"],
})
export class OtherInfoTabComponent implements OnInit {
  statistics: StatisticForPath[] = [];

  constructor(
    public globalPracticeEditorService: PracticePathGlobalVideoEditorPageService,
    private dialogService:MatDialog
  ) {}

  ngOnInit(): void {
    this.statistics = this.getStatisticData();

    this.globalPracticeEditorService.timedIconAssignments$.addListener(() => {
      this.statistics = this.getStatisticData();
    });

    this.globalPracticeEditorService.pathIconAssignments$.addListener(() => {
      this.statistics = this.getStatisticData();
    });
  }

  getStatisticData(): StatisticForPath[] {
    const assignedPointLength =
      this.globalPracticeEditorService.criticalPointAssignments$.length;
    const allCriticalPointLength =
      this.globalPracticeEditorService.criticalPointsInCorrespondingCity$
        .length;
    const pathLength = this.globalPracticeEditorService.path$.length;
    const countOfTimedIcon =
      this.globalPracticeEditorService.timedIconAssignments$.length;
    const countOfPathIcon =
      this.globalPracticeEditorService.pathIconAssignments$.length;

    // tmp hoztuk létre a kritikus pontok szövegének az össz karakter számához (narráció pricing)
    const countOfCharacterOfAllCriticalPoint =
      this.globalPracticeEditorService.city.criticalPoints
        .map((cps) => cps.description)
        .reduce((prev, current) => prev + current.length, 0);

    return [
      {
        displayedName: "Hozzárendelt kritikus p.",
        value: assignedPointLength,
      },
      {
        displayedName: "Összes kritikus p. a városban",
        value: allCriticalPointLength,
      },
      {
        displayedName: "Útvonal (path) pontok száma",
        value: pathLength,
      },
      {
        displayedName: "Időzített ikonok száma",
        value: countOfTimedIcon,
      },
      {
        displayedName: "Útvonal ikonok száma",
        value: countOfPathIcon,
      },
    ];
  }

  protected getVideoType():PracticePathVideoType {
    return this.globalPracticeEditorService.determineVideoUrl(this.globalPracticeEditorService.practicePath).type;
  }

  /**
   * Handles the 'open narrations' button click. It opens the narrations during recording dialog.
   */
  protected onOpenNarrationsButtonClick():void {
    NarrationsDuringRecordingDialogComponent.open(
      this.dialogService,
      {
        practicePathUuid: this.globalPracticeEditorService.practicePath.uuid,
        initialTab: "list"
      }
    );
  }
}
