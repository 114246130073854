import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { NotifierService } from 'angular-notifier';
import { Subscription } from 'rxjs';
import { AnonymizerTaskService } from 'src/app/services/anonymizer-task.service';
import { AnonymizerService } from 'src/app/services/anonymizer.service';
import { AnonymizerTasksPageInitialization, AnonymizerTasksPageService } from "./services/anonymizer-tasks-page.service";
import { PracticePathCacherService } from '../../../../../services/cacher-services/practice-path-cacher.service';
import { PracticeCityCacherService } from '../../../../../services/cacher-services/practice-city-cacher.service';

@Component({
  selector: 'app-anonymizer-tasks',
  templateUrl: './anonymizer-tasks.component.html',
  styleUrls: ['./anonymizer-tasks.component.scss'],
  providers: [
    AnonymizerTasksPageService,
    PracticePathCacherService,
    PracticeCityCacherService
  ]
})
export class AnonymizerTasksComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: false }) set paginator(paginator:MatPaginator) {
    if(paginator) {
      this.pageChangeSubscription = paginator.page.subscribe(this.onPage)
    }
  }

  protected initState:AnonymizerTasksPageInitialization;
  protected readonly AnonymizerTasksPageInitialization:typeof AnonymizerTasksPageInitialization = AnonymizerTasksPageInitialization;

  protected lastUpdatedOn:number|null = null;

  private pageChangeSubscription:Subscription;
  private anonyimizingTasksChangeSubscription:Subscription;

  protected taskStatusSelectFC:FormControl<boolean>;

  private readonly defaultTaskStatusSelectValue:boolean = false;

  protected manualSyncInProgress:boolean = false;
  protected slaveAdditionInProgress:boolean = false;

  constructor(
    protected anonymizerTasksPageService:AnonymizerTasksPageService,
    private anonymizerTaskService:AnonymizerTaskService,
    private anonyimizerService:AnonymizerService,
    private notifierService:NotifierService,
    private practiceCityCacherService:PracticeCityCacherService,
    private practicePathCacherService:PracticePathCacherService,
  ) { }

  public ngOnInit():void {
    // Initialize the cacher services queried fields
    this.practiceCityCacherService.queriedFields = [
      "uuid", "city"
    ];
    this.practicePathCacherService.queriedFields = [
      "uuid", "name", "isFullPracticePath", "autoIncrementId", "examPathNumber", "cityUuid" 
    ];

    // Initalize the form control and subscribe to it's value change
    this.initializeFormControl();

    // Subscribe to the fetched anonymizer tasks data change
    this.subscribeToAnonymizerTaksDataChange();

    // Initalize (through the page's service) the displayed anonymizer tasks
    this.initializeAnonymizerTasks();
  }

  public ngOnDestroy():void {
    this.pageChangeSubscription?.unsubscribe();
    this.anonyimizingTasksChangeSubscription?.unsubscribe();
  }

  /**
   * Initalize (through the page's service) the displayed anonymizer tasks. It set's the page's
   * state during the setup according to the initialization's actual state.
   */
  protected async initializeAnonymizerTasks():Promise<void> {
    // Set the initialization state to loading
    this.initState = AnonymizerTasksPageInitialization.Loading;


    try {
      // Initialize the service (which fetches the initial data aswell)
      await this.anonymizerTasksPageService.initalize(this.taskStatusSelectFC.value);
    } catch(error:any) {
      // If an error happened during the initialization, set the init state to error
      console.error(error);
      this.initState = AnonymizerTasksPageInitialization.Error;
      return;
    }

    // At this point the component could fully initialize, so we can set the init state to ready
    this.initState = AnonymizerTasksPageInitialization.Ready;
  }

  /**
   * Initializes the component's selector's form control. It also subscribes to it's value change
   * and updates the actual listed tasks' type in the `AnonymizerTasksPageService`.
   */
  private initializeFormControl():void {
    this.taskStatusSelectFC = new FormControl<boolean>(this.defaultTaskStatusSelectValue);
    this.taskStatusSelectFC.valueChanges.subscribe(
      async (newValue:boolean) => {
        await this.anonymizerTasksPageService.updateListedTaskType(newValue);
      }
    )
  }

  /**
   * Subscribes to the fetched anonymizer tasks data changing. On every change it refreshes the `lastUpdatedOn`
   * member variable's value.
   */
  private subscribeToAnonymizerTaksDataChange():void {
    this.anonyimizingTasksChangeSubscription = this.anonymizerTasksPageService.anonymizerTasks$.subscribe(
      () => {
        this.lastUpdatedOn = Date.now();
      }
    );
  }


  /**
   * Handles the paging event with the component's paginator. It uses the `AnonymizerTasksPageService`
   * to set the actual page index.
   * 
   * @param pageEvent the page event
   */
  private onPage = (pageEvent:PageEvent) => {
    this.anonymizerTasksPageService.setPageIndex(pageEvent.pageIndex);
  }

  /**
   * Handles the "Syncronize" button click. It launches a syncronization request through the `AnonymizerTaskService`
   * and updates the displayed 
   */
  protected async onSynchronizeButtonClick():Promise<void> {
    this.manualSyncInProgress = true;
    
    try {
      await this.anonymizerTaskService.syncAnonymizedVideos();
      await this.anonymizerTasksPageService.updateAnonymizerTasks();
    } catch(error:any) {
      console.error(error);
    }

    this.manualSyncInProgress = false;
  }

  /**
   *  Handles the "Add slave" button click. Adds a slave to the anonymizer system.
   */
  protected async onAddSlaveButtonClick():Promise<void> {
    this.slaveAdditionInProgress = true;

    try {
      await this.anonyimizerService.requestAddSlaveToSystem();
      this.notifierService.notify("success", "Sikeres slave hozzáadás.")
    } catch(error:any) {
      console.error(error);
    }

    this.slaveAdditionInProgress = false;
  }

}
