import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Permission } from 'src/app/classes/model/permissions';
import { Vendor } from 'src/app/classes/model/vendor';
import { PermissionService } from 'src/app/services/common/permission.service';
import { ModifyVendorDialogComponent, ModifyVendorDialogOutput } from '../../../modify-vendor-dialog/modify-vendor-dialog.component';

@Component({
  selector: 'app-vendor-base-informations-tab',
  templateUrl: './vendor-base-informations-tab.component.html',
  styleUrls: ['./vendor-base-informations-tab.component.scss']
})
export class VendorBaseInformationsTabComponent implements OnInit {
  @Input() vendor:Vendor;

  constructor(
    private dialogService:MatDialog,
    private permissionService:PermissionService
  ) { }

  public ngOnInit(): void { }

  protected isVendorAbleToSendPaymentRequest():boolean {
    return this.vendor.permissions.includes(Permission.PaymentRequest);
  }

  protected onEditButtonClick():void {
    const dialogRef:MatDialogRef<ModifyVendorDialogComponent, ModifyVendorDialogOutput> = ModifyVendorDialogComponent.open(
      this.dialogService,
      { vendor: this.vendor }
    );

    dialogRef.afterClosed().subscribe(
      (dialogOutput:ModifyVendorDialogOutput|null) => {
        if(dialogOutput) {
          Object.assign(this.vendor, dialogOutput);
        }
      }
    );
  }

  /**
   * Determines that the current admin user has the vendor write permission.
   * 
   * @returns true if the admin has the permission, false otherwise
   */
  protected hasAdminVendorWritePermission():boolean {
    return this.permissionService.isLoggedUserHasPermission(Permission.VendorWrite);
  }

}
