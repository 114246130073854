<!-- Question edit dialog title -->
<div id = "question-edit-dialog-header">
    <div id = "question-edit-dialog-title">
        {{ title }}
    </div>
    <div id = "question-edit-dialog-updated-container">
        {{ questionIsUpdatedFC.value ? "Frissítve" : "Nincs frissítve" }}
        <mat-slide-toggle formControlName = "questionIsUpdatedFC"></mat-slide-toggle>
    </div>
</div>

    <!-- Category edit dialog body -->
<div class = "dialog-content">
    <form [formGroup] = "questionFG">

        <div *ngIf = "data.question !== null && data.question.metadata.id !== undefined" id = "question-edit-id">
            Kódszám: {{ data.question.metadata.id }}
        </div>
        <!-- Question text form -->
            <mat-form-field appearance='outline' id = "question-text-form" >
            <mat-label>Kérdés szövege</mat-label>
            <textarea id = "question-text-textarea" matInput formControlName = "questionTextFC" required></textarea>
            <mat-error *ngIf = "questionTextFC.hasError('required')">Kötelező mező!</mat-error>
        </mat-form-field>

        <!-- Question options form -->
        <div id = "question-edit-options-container">
            <fieldset id = "question-edit-options-fieldset">
                <legend> Válaszlehetőségek </legend>
                    <mat-form-field appearance='outline' >
                    <mat-label>Válasz típusa</mat-label>
                    <mat-select formControlName = "optionTypeSelectFC">
                        <mat-option [value] = "OptionType.Simple"> Helyes kiválasztása </mat-option>
                        <mat-option [value] = "OptionType.Arrangeable"> Sorbarendezés </mat-option>
                    </mat-select>
                </mat-form-field>
                <!-- Simple question options form -->
                <div *ngIf = "optionType === OptionType.Simple">
                    <table id = "question-options-table">
                        <tr>
                            <td class = "question-option-text-td"></td>
                            <td class = "question-center-aligned-td"> Megoldás </td>
                            <td class = "question-center-aligned-td"> Törlés </td>
                        </tr>
                        <tr *ngFor = "let optionFCId of optionsControl.optionFCIds; let i = index">
                            <td>
                                    <mat-form-field appearance='outline'  class = "question-option-text-form" >
                                    <mat-label>Lehetőség #{{ i + 1 }}</mat-label>
                                    <textarea matInput [formControlName] = "OptionsControl.optionTextFCName + optionFCId" class = "question-option-text-textarea" type = "text" required>
                                    </textarea>
                                </mat-form-field>
                            </td>
                            <td class = "question-center-aligned-td">
                                <mat-checkbox class="example-margin" [formControlName] = "OptionsControl.optionSolutionFCName + optionFCId"></mat-checkbox>
                            </td>
                            <td class = "question-option-delete-td question-center-aligned-td">
                                <button mat-mini-fab color = "warn" (click) = "optionsControl.deleteOptionForm(i)">
                                    <mat-icon> delete </mat-icon>
                                </button>
                            </td>
                        </tr>
                    </table>
                    <button mat-mini-fab color = "primary" type = "button" (click) = "optionsControl.addOptionForm()" > + </button>
                </div>
                <!-- Arrangeable question options form -->
                <div *ngIf = "optionType === OptionType.Arrangeable" >
                    <div cdkDropList class="example-list" (cdkDropListDropped)="onArrangeableAnswerDragEnd($event)" >
                        <mat-card *ngFor = "let optionText of displayedArrangeableOptions;let i = index" class = "question-arrangeable-option" cdkDrag>
                            <div>
                                {{ optionText }}
                            </div>
                            <button mat-mini-fab color = "primary" (click) = "deleteArrangeableOption(i)">
                                <mat-icon> delete </mat-icon>
                            </button>
                        </mat-card>
                    </div>
                    <div *ngIf = "displayedArrangeableOptions.length === 0" id = "question-options-no-arrangeable-options">
                        Jelenleg nincs válaszlehetőség hozzáadva a kérdéshez.
                    </div>
                    <div id = "question-options-new-arrangeable-container">
                        <div id = "question-options-new-arrangeable-form-container">
                                <mat-form-field appearance='outline' id = "question-options-new-arrangeable-form" >
                                <mat-label>Válaszlehetőség szövege</mat-label>
                                <input matInput formControlName = "newArrangeableOptionFC" />
                            </mat-form-field>
                        </div>
                        <div id = "question-options-new-arrangeable-button-container">
                            <button mat-raised-button color = "primary" (click) = "addArrangeableOption()" [disabled] = "newArrangeableOptionFC.value === ''" > Válasz felvétele </button>
                        </div>
                    </div>
                </div>
            </fieldset>
            <mat-error *ngIf = "!optionsControl.isNumberOfSolutionsChanged && optionsControl.numberOfSolutions !== 1">Pontosan egy megoldásnak kell szerepelnie!</mat-error>
            <mat-error *ngIf = "displayArrangeableError">Legalább két válaszlehetőségnek szerepelnie kell!</mat-error>
        </div>

        <!-- Question explanation form -->
            <mat-form-field appearance='outline' class = "question-explanation-form">
            <mat-label>Magyarázat</mat-label>
            <textarea matInput class = "question-explanation-textarea" formControlName = "questionExplanationFC"></textarea>
        </mat-form-field>

        <!-- Question media form -->
        <div id = "question-media-container">
            <div id = "question-media-preview-div">
                <img *ngIf = "uploadedMediaType !== MediaType.Video" id = "question-media-preview-media" [src] = "getMediaUrl()" draggable = "false" />
                <video *ngIf = "uploadedMediaType == MediaType.Video" id = "question-media-preview-media" [src] = "getMediaUrl()" controls></video>
            </div>
            <div id = "question-media-input-div">
                <div id = "question-media-input-wrapper">
                    Feltöltés
                    <input id = "question-media-input" accept = "image/jpeg, video/mp4" type = 'file' />
                </div>

                <div>
                    <button mat-raised-button (click) = "removeMedia()"> Média törlése </button>
                </div>

                <div>
                    <mat-slide-toggle formControlName = "questionHasIllustrationFC"> Illusztráció </mat-slide-toggle>
                </div>
            </div>
        </div>

        <div id = "question-categories-and-questiongroups">
            <div>
                <div class = "question-assign-header">
                    <div class = "question-assign-title"> Hozzárendelt pozíciók: </div>
                    <div *ngIf = "isCategoryAssignmentEnabled" class = "question-assign-edit">
                        <button mat-mini-fab color = "primary" class = "question-assign-edit-button" (click) = "openQuestionCategoryAssignmentEditDialog()">
                            <mat-icon> edit </mat-icon>
                        </button>
                    </div>
                </div>
                <div *ngFor = "let questionPositions of question.categories" class = "question-position-item">
                    {{ categoryName(questionPositions.categoryUuid) + ':' }}
                    <span *ngFor = "let position of questionPositions.positions">
                        {{ (position + 1) + ' ' }}
                    </span>
                </div>
                <div *ngIf = "isCategoryAssignmentEnabled && question.categories.length === 0" class = "question-edit-no-assignment">
                    A kérdés nincs jelenleg pozícióhoz rendelve.
                </div>
                <div *ngIf = "!isCategoryAssignmentEnabled" class = "question-edit-no-assignment">
                    Sorbarendezős kérdés nem rendelhető kategóriához.
                </div>
            </div>
            <mat-divider [vertical] = "true"></mat-divider>
            <div>
                <div class = "question-assign-header">
                    <div class = "question-assign-title"> Hozzárendelt kérdéscsoportok: </div>
                <div class = "question-assign-edit">
                    <button mat-mini-fab color = "primary" class = "question-assign-edit-button" (click) = "openQuestionQuestiongroupAssignmentEditDialog()">
                        <mat-icon> edit </mat-icon>
                    </button>
                </div>
                </div>
                <div *ngFor = "let questionGroupUuid of question.questionGroups" class = "question-position-item">
                    {{ questionGroupName(questionGroupUuid) }}
                </div>
                <div *ngIf = "question.questionGroups.length === 0" class = "question-edit-no-assignment">
                    A kérdés nincs jelenleg kérdéscsoporthoz rendelve.
                </div>
            </div>

        </div>

        <div style = "text-align: center">
            <button mat-flat-button color = "primary" (click) = "clearAssignments()"> Hozzárendelések törlése </button>
        </div>
    </form>
</div>


<!-- Question dialog actions -->
<div id = "question-dialog-actions">
    <div *ngIf = "data.dialogMode === DialogMode.Add" id = "question-dialog-add-actions">
        <button mat-button mat-dialog-close> Vissza </button>
        <button id = "question-dialog-add-button" mat-button (click) = "handleAddButtonClick()" cdkFocusInitial> Felvesz </button>
    </div>
    <div *ngIf = "data.dialogMode === DialogMode.Edit" id = "question-dialog-edit-actions">
        <div id = "question-dialog-actions-delete-container">
            <button mat-button id  = "question-dialog-delete-button" (click) = "handleDeleteButtonClick()"> Törlés </button>
        </div>
        <div id = "question-dialog-actions-modify-container">
            <button mat-button mat-dialog-close> Vissza </button>
            <button mat-button id = "question-dialog-modify-button"  (click) = "handleModifyButtonClick()" cdkFocusInitial> Módosít </button>
        </div>
    </div>
</div>