import { Component, Inject, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NotifierService } from "angular-notifier";
import { CustomValidators } from "src/app/classes/custom-validators";
import { PracticeCity } from "src/app/classes/model/practice-city";
import { matchingPropertyPredicate } from "src/app/functions/misc";
import { ConfirmationDialogService } from "src/app/modules/confirmation-dialog/services/confirmation-dialog.service";
import { PracticeCityNodeService } from "src/app/services/practice-city-node.service";
import { PracticeCityNodeEditorComponent } from "./practice-city-node-editor/practice-city-node-editor.component";

@Component({
  selector: "app-practice-city-nodes",
  templateUrl: "./practice-city-nodes.component.html",
  styleUrls: ["./practice-city-nodes.component.scss"],
})
export class PracticeCityNodesComponent implements OnInit {
  title: string;
  nodeNameFC: FormControl;
  nodeCreationLoading: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { practiceCity: PracticeCity },
    private practiceCityNodeService: PracticeCityNodeService,
    private dialogService: MatDialog,
    protected snackBarService: MatSnackBar,
    private confirmationDialogService: ConfirmationDialogService,
    private notifierService:NotifierService
  ) {
    this.nodeNameFC = new FormControl("", [
      CustomValidators.maximalLineCount(2),
    ]);
  }

  ngOnInit(): void {
    this.title = `${this.data.practiceCity.city}${
      !this.data.practiceCity.city.endsWith("i") ? "i" : ""
    } érintési pontok módosítása`;
  }

  public async addNodeToCity(): Promise<void> {
    const nodeName: string = this.nodeNameFC.value;
    if (
      this.data.practiceCity.nodes.find(
        matchingPropertyPredicate("name", nodeName)
      )
    ) {
      this.notifierService.notify("error", "Már létezik érintési pont ezzel a névvel.");
      return;
    }

    this.nodeCreationLoading = true;
    try {
      await this.practiceCityNodeService.addNodeToCity(this.data.practiceCity, {
        name: nodeName,
      });
      this.nodeNameFC.setValue("");
      this.snackBarService.open("Érintési pont sikeresen létrehozva.", "Bezár", {
        duration: 3000,
        panelClass: ["mat-snackbar-success"],
      });
    } catch (error: any) {}
    this.nodeCreationLoading = false;
  }

  public deleteNodeFromCity(index: number): void {
    this.confirmationDialogService.open(
      "Érintési pont törlése",
      `Biztonsan törlöd a(z) ${this.data.practiceCity.nodes[index].name} érintési pontot?`,
      async () => {
        try {
          await this.practiceCityNodeService.deleteNodeFromCity(
            this.data.practiceCity,
            this.data.practiceCity.nodes[index].uuid
          );
          this.snackBarService.open("Érintési pont sikeresen törölve.", "Bezár", {
            duration: 3000,
            panelClass: ["mat-snackbar-success"],
          });
        } catch (error: any) {}
      }
    );
  }

  public editNode(index: number): void {
    this.dialogService.open(PracticeCityNodeEditorComponent, {
      data: {
        node: this.data.practiceCity.nodes[index],
        maxPosition: this.data.practiceCity.countOfExamPath,
        city: this.data.practiceCity,
      },
    });
  }
}
