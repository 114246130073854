import {
  IconPath,
} from "./../classes/model/practice-path";
import { Injectable } from "@angular/core";
import {
  CriticalPoint,
} from "../classes/model/practice-path";
import { BackendService } from "./common/backend.service";
import { HttpErrorHandlerService } from "./common/http-error-handler.service";
import { HttpResponseData } from "../classes/http-communication";

// Időzített ikon hozzárendelésre/törlésre/módosításra használhatjuk egy útvonalon belül

@Injectable({
  providedIn: "root",
})
export class PathIconService {
  // teljes: practicePathApiUrlFragment/:practicePathUuid/path_icons/:pathIconUuid?

  private readonly practicePathAssignmentApiUrlFragment: string =
    "/api/admin/practice_path/";
  constructor(
    private backendService: BackendService,
    private httpErrorHandlerService: HttpErrorHandlerService
  ) {}

  // Töröl egy időzített ikon hozzárendelést egy útvonalból
  public async removePathIconAssignment(
    practicePath: string,
    pathIconAssignmentUuid: string
  ): Promise<boolean> {
    try {
      const response: HttpResponseData<Array<CriticalPoint>> =
        await this.backendService.callApi(
          this.practicePathAssignmentApiUrlFragment +
            practicePath +
            "/path_icons/" +
            pathIconAssignmentUuid,
          "DELETE"
        );
      return true;
    } catch (error: any) {
      this.httpErrorHandlerService.handleError(
        error,
        "Hiba időzített ikon törlése közben!"
      );
      return false;
    }
  }

  // Egy új útvonal ikont hoz létre a városban
  // Visszatér a beszúrt útvonal ikonnal (uuid-val ellátva)
  // vagy undefined-al ha nem sikerült
  public async addPathIconAssignment(
    practicePathUuid: string,
    iconPathAssignment: Omit<IconPath, "uuid"> // uuid-t nem kell megadni!
  ): Promise<IconPath | undefined> {
    try {
      const response: {
        insertedIcon: IconPath;
      } = await this.backendService.callApi(
        this.practicePathAssignmentApiUrlFragment +
          practicePathUuid +
          "/path_icons/",
        "PUT",
        {
          item: iconPathAssignment,
        }
      );
      return response.insertedIcon;
    } catch (error: any) {
      this.httpErrorHandlerService.handleError(
        error,
        "Hiba útvonal ikon hozzáadásakor!"
      );
      return undefined;
    }
  }

  // Egy 'útvonal ikon' hozzárendelést kicserél egy útvonalban
  public async replacePathIconAssignment(
    practicePathUuid: string,
    iconPathAssignmentUuid: string,
    newIconPath: IconPath
  ) : Promise<boolean> {
    try {
      const response: HttpResponseData<Array<CriticalPoint>> =
        await this.backendService.callApi(
          this.practicePathAssignmentApiUrlFragment +
            practicePathUuid +
            "/path_icons/" +
            iconPathAssignmentUuid,
          "POST",
          {
            item: newIconPath
          }
        );
      return true;
    } catch (error: any) {
      this.httpErrorHandlerService.handleError(
        error,
        "Hiba útvonal ikon módosításakor!"
      );
      return false;
    }
  }
}
