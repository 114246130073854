import { NotifierService } from "angular-notifier";
import { MrVegasMetadataFileLoader } from "./services/mrvegas-metadata-file-loader";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { MrVegasVideoMetadata } from "./models/mrvegas-video-metadata";

@Component({
  selector: "app-mrvegas-metadata-viewer-page",
  templateUrl: "./mrvegas-metadata-viewer-page.component.html",
  styleUrls: ["./mrvegas-metadata-viewer-page.component.scss"],
})
export class MrvegasMetadataViewerPageComponent implements OnInit {
  currentLoadedMetadata: MrVegasVideoMetadata | null = null;

  constructor(
    private mrVegasMetadataLoader: MrVegasMetadataFileLoader,
    private notifier: NotifierService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {}

  public async droppedMetadataFile(f: any) {
    const files = f.addedFiles;

    if (files.length != 1) {
      this.notifier.notify("error", "Pontosan 1 fájlt kell kijelölnöd!");
      return;
    }

    try {
      this.currentLoadedMetadata =
        await this.mrVegasMetadataLoader.checkValidityAndLoadMetadataFromFile(
          files[0]
        );
    } catch (e) {
      this.notifier.notify("error", `Sikertelen betöltés ${e}`);
    }
  }
}
