import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/classes/custom-validators';
import { Permission } from 'src/app/classes/model/permissions';
import { Vendor, VendorType } from 'src/app/classes/model/vendor';

@Component({
  selector: 'app-vendor-data-form',
  templateUrl: './vendor-data-form.component.html',
  styleUrls: ['./vendor-data-form.component.scss']
})
export class VendorDataFormComponent implements OnInit {
  @Input() mode:"add"|"edit";
  @Input() defaultValues?:Pick<Vendor, "email"|"name"|"type"|"phoneNumber"|"permissions">;

  protected emailFC:FormControl<string>;
  protected nameFC:FormControl<string>;
  protected typeFC:FormControl<VendorType|"">;
  protected phoneNumberFC:FormControl<string>;
  protected canSendPaymentRequestFC:FormControl<boolean>;

  private formGroup:FormGroup;

  vendorTypes:Array<{ name:string, displayedName:string }> = [
    { name: "instructor", displayedName: "Oktató" },
    { name: "school", displayedName: "Autósiskola" },
    { name: "other", displayedName: "Egyéb" }
  ];

  constructor() { }

  ngOnInit(): void {
    this.initalizeFormControls();
  }

  private initalizeFormControls():void {
    // E-mail form control
    this.emailFC = new FormControl<string>(
      this.defaultValues?.email ?? "",
      [ Validators.required, CustomValidators.email ]
    );

    // Name form control
    this.nameFC = new FormControl<string>(
      this.defaultValues?.name ?? "",
      [ Validators.required ]
    );

    // Vendor type form control
    this.typeFC = new FormControl<VendorType|"">(
      this.defaultValues?.type ?? "",
      [ Validators.required ]
    );

    // Phone number form control
    this.phoneNumberFC = new FormControl(
      this.defaultValues?.phoneNumber ?? "",
      [ Validators.pattern(new RegExp("^(\\+)([0-9]{10,11})$")) ]
    );

    // Can send payment request form control
    this.canSendPaymentRequestFC = new FormControl<boolean>(
      this.defaultValues?.permissions.includes(Permission.PaymentRequest) ?? false
    );

    // Create the from group
    this.formGroup = new FormGroup({
      emailFC: this.emailFC,
      nameFC: this.nameFC,
      typeFC: this.typeFC,
      phoneNumberFC: this.phoneNumberFC,
      canSendPaymentRequestFC: this.canSendPaymentRequestFC
    });

    // If the mode is edit, disable the immutable fields
    if(this.mode === "edit") {
      this.emailFC.disable();
      this.nameFC.disable();
      this.typeFC.disable();
    }
  }

  public isFormValid():boolean {
    return this.formGroup.valid;
  }

  public getFormData():VendorDataFormResult {
    return {
      email: this.emailFC.value,
      name: this.nameFC.value,
      type: this.typeFC.value as VendorType,
      phoneNumber: this.phoneNumberFC.value,
      canSendPaymentRequest: this.canSendPaymentRequestFC.value
    };
  }

}

export type VendorDataFormResult =
  Pick<Vendor, "email"|"name"|"type"|"phoneNumber">&
  { canSendPaymentRequest:boolean };
