import { CriticalPointAssignment } from "./../classes/model/practice-path";
import { Injectable } from "@angular/core";
import { CriticalPoint } from "../classes/model/practice-path";
import { BackendService } from "./common/backend.service";
import { HttpErrorHandlerService } from "./common/http-error-handler.service";
import { HttpResponseData } from "../classes/http-communication";

@Injectable({
  providedIn: "root",
})
export class CriticalPointAssignmentService {
  // teljes: practicePathApiUrlFragment/:practicePathUuid/assignments/:assignmentUuid?
  private readonly practicePathAssignmentApiUrlFragment: string =
    "/api/admin/practice_path/";
  constructor(
    private backendService: BackendService,
    private httpErrorHandlerService: HttpErrorHandlerService
  ) {}

  // Melyik városban, melyik kritikus pontot szeretnénk törölni
  // igazzal tér vissza, ha sikerült a törlés
  public async removeCriticalPointAssignment(
    practicePath: string,
    assignmentUuid: string
  ): Promise<boolean> {
    try {
      const response: HttpResponseData<Array<CriticalPoint>> =
        await this.backendService.callApi(
          this.practicePathAssignmentApiUrlFragment +
            practicePath +
            "/assignments/" +
            assignmentUuid,
          "DELETE"
        );
      return true;
    } catch (error: any) {
      this.httpErrorHandlerService.handleError(
        error,
        "Hiba kritikus pont hozzárendelés törlése közben"
      );
      return false;
    }
  }

  // Egy új kritikus pontot hoz létre a városban
  // Visszatér a beszúrt kritikus ponttal
  // vagy undefined-al ha nem sikerült
  public async addCriticalPointAssignment(
    practicePathUuid: string,
    assignment: CriticalPointAssignment
  ): Promise<CriticalPointAssignment | undefined> {
    try {
      const response: {
        insertedCriticalPointAssignment: CriticalPointAssignment;
      } = await this.backendService.callApi(
        this.practicePathAssignmentApiUrlFragment +
          practicePathUuid +
          "/assignments/",
        "PUT",
        assignment
      );
      return response.insertedCriticalPointAssignment;
    } catch (error: any) {
      this.httpErrorHandlerService.handleError(
        error,
        "Hiba kritikus pont hozzárendelés hozzáadásakor"
      );
      return undefined;
    }
  }

  // Egy kritikus pontot hozzárendelést teljesen kicserél egy practice path-en belül
  public async replaceCriticalPointAssignmentInPracticePath(
    practicePathUuid: string,
    assignmentUuid: string,
    newAssignment: CriticalPointAssignment
  ) {
    try {
      const response: HttpResponseData<Array<CriticalPoint>> =
        await this.backendService.callApi(
          this.practicePathAssignmentApiUrlFragment +
            practicePathUuid +
            "/assignments/" +
            assignmentUuid,
          "POST",
          newAssignment
        );
      return true;
    } catch (error: any) {
      this.httpErrorHandlerService.handleError(
        error,
        "Hiba kritikus pont hozzárendelés módosításakor"
      );
      return false;
    }
  }
}
