import { NotifierService } from 'angular-notifier';
import { SingleMarkerController } from "./../single-marker-controller";
import { Component, Input, OnChanges, OnInit } from "@angular/core";

@Component({
  selector: "app-follow-single-marker-controller-button",
  templateUrl: "./follow-single-marker-controller-button.component.html",
  styleUrls: ["./follow-single-marker-controller-button.component.scss"],
})
export class FollowSingleMarkerControllerButtonComponent
  implements OnInit, OnChanges
{
  shouldFollow: boolean;

  @Input()
  name: string;

  @Input()
  singleMarkerController: SingleMarkerController;

  ngOnChanges() {
    // Ha a singleMarkerController változik akkor annak megfelelően állítsuk be a shouldFollow értékét
    if (
      this.singleMarkerController?.singleMarkerOptions
        ?.shouldMapCenterFollowMarker != null
    ) {
      this.shouldFollow =
        this.singleMarkerController?.singleMarkerOptions?.shouldMapCenterFollowMarker ?? false;
    }
  }

  constructor(private notifierService: NotifierService) {}

  ngOnInit(): void {
    this.shouldFollow =
      this.singleMarkerController?.singleMarkerOptions?.shouldMapCenterFollowMarker ?? false;
  }

  onTapButton() {
    this.shouldFollow = !this.shouldFollow;
    if(this.shouldFollow){
      this.notifierService.notify('success', 'Autó követés bekapcsolva!');
    }
    this.singleMarkerController.setMarkerFollowEnabled(this.shouldFollow);
  }
}
