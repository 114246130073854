import { CriticalPointEventName, PracticePathEventName, LoggedPracticalModuleEntityType, PracticalModuleLog, getEventNameOfPracticalModuleLog, getRealTypeOfPracticalModuleLog, GeneralPracticalModuleLog, CriticalPointAssignmentEventName } from './practical-module-log.model'

// Default colors
const defaultCreationColor: string = "rgb(185, 255, 162)";
const defaultDeletionColor: string = "rgba(255, 161, 161, 1)";
const defaultModificationColor: string = "rgb(255, 218, 152)";
export const unidentifiedEventColor: string = "#FF0000";
export const generalLogColor: string = "#CCCCCC";

export const generalEventName = "general";

export type PracticeModuleEventDescription<EventType> = {
  eventName: EventType | typeof generalEventName;
  displayedName: string;
  color: string;
}

export class PracticeModuleEventLogGroup<EventType>{
  entityType: string;
  groupName: string;
  eventDescriptions: Array<PracticeModuleEventDescription<EventType>>

  constructor(entityType: string, groupName: string, eventDescriptions: Array<PracticeModuleEventDescription<EventType>>) {
    this.entityType = entityType;
    this.groupName = groupName;
    this.eventDescriptions = eventDescriptions;
  }
}

export class CriticalPointEventLogGroup extends PracticeModuleEventLogGroup<CriticalPointEventName>{
  constructor(eventDescriptions: Array<PracticeModuleEventDescription<CriticalPointEventName>>) {
    super("critical_point", "Kritikus pont", eventDescriptions);
  }
}

export class CriticalPointAssignmentEventLogGroup extends PracticeModuleEventLogGroup<CriticalPointAssignmentEventName>{
  constructor(eventDescriptions: Array<PracticeModuleEventDescription<CriticalPointAssignmentEventName>>) {
    super("critical_point_assignment", "Kritikus pont hozzárendelés", eventDescriptions);
  }
}

export class PracticePathEventLogGroup extends PracticeModuleEventLogGroup<PracticePathEventName>{
  constructor(eventDescriptions: Array<PracticeModuleEventDescription<PracticePathEventName>>) {
    super("practice_path", "Vizsgaútvonal", eventDescriptions);
  }
}


export const practiceModuleEventLogGroups: Array<PracticeModuleEventLogGroup<string>> = [
  new CriticalPointEventLogGroup([
    { eventName: "creation", displayedName: "Létrehozás", color: defaultCreationColor },
    { eventName: "deletion", displayedName: "Törlés", color: defaultDeletionColor },
    { eventName: "base_data_modification", displayedName: "Alapadat módosítás", color: defaultModificationColor },
    { eventName: "verification_change", displayedName: "Hitelesítés módosítás", color: "#78CCE6" },
    { eventName: "general", displayedName: "Egyéb műveletek", color: generalLogColor }
  ]),
  new CriticalPointAssignmentEventLogGroup([
    { eventName: "creation", displayedName: "Létrehozás", color: defaultCreationColor },
    { eventName: "deletion", displayedName: "Törlés", color: defaultDeletionColor },
    { eventName: "modification", displayedName: "Módosítás", color: defaultModificationColor },
    { eventName: "general", displayedName: "Egyéb műveletek", color: generalLogColor }
  ]),
  new PracticePathEventLogGroup([
    { eventName: "creation", displayedName: "Létrehozás", color: defaultCreationColor },
    { eventName: "deletion", displayedName: "Törlés", color: defaultDeletionColor },
    { eventName: "base_data_modification", displayedName: "Alapadat módosítás", color: defaultModificationColor },
    { eventName: "path_modification", displayedName: "Útvonal módosítás", color: "#E3E346" },
    { eventName: "general", displayedName: "Egyéb műveletek", color: generalLogColor }
  ])
];

export function getDisplayedEntityAndEventName(practicalModuleLog: PracticalModuleLog): string {
  const practiceModuleEventLogGroup: PracticeModuleEventLogGroup<string> | undefined = practiceModuleEventLogGroups.find(
    (practiceModuleEventLogGroup: PracticeModuleEventLogGroup<string>) => {
      return practiceModuleEventLogGroup.entityType === practicalModuleLog.loggedEntityType
    }
  );
  const displayedEntityName: string = practiceModuleEventLogGroup?.groupName ?? "Ismeretlen entitás";

  let displayedEventName: string;
  if (getRealTypeOfPracticalModuleLog(practicalModuleLog) == GeneralPracticalModuleLog) {
    displayedEventName = "általános log"
  } else {
    const eventDescription: PracticeModuleEventDescription<string> | undefined =
      practiceModuleEventLogGroup?.eventDescriptions.find(
        (eventDescription: PracticeModuleEventDescription<string>) => {
          return eventDescription.eventName === getEventNameOfPracticalModuleLog(practicalModuleLog);
        }
      );
    displayedEventName = eventDescription?.displayedName.toLocaleLowerCase() ?? "ismeretlen művelet";
  }
  
  return `${displayedEntityName} ${displayedEventName}`;
}