import { CustomValidators } from './../../../../classes/custom-validators';
import { PracticeCityZone } from './../../../../classes/model/practice-city-zone';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PracticeCityZoneService } from '../../services/practice-city-zone-service';
import { NotifierService } from 'angular-notifier';

export type CityZoneDialogEditData = {
  zone: PracticeCityZone
}

export type CityZoneDialogEditOutput = PracticeCityZone | undefined;

@Component({
  selector: 'app-practice-city-zone-dialog-edit',
  templateUrl: './city-zone-dialog-edit.component.html',
  styleUrls: ['./city-zone-dialog-edit.component.scss']
})
export class CityZoneDialogEditComponent implements OnInit {

  nameFC: FormControl;
  isFreeFC: FormControl;
  isVisibleFC: FormControl;
  imageFC: FormControl;
  isMainNodeFC: FormControl;
  assignedPositions: Array<number>;

  constructor(
    private dialogRef: MatDialogRef<CityZoneDialogEditComponent, CityZoneDialogEditOutput>,
    @Inject(MAT_DIALOG_DATA) public data: CityZoneDialogEditData,
    protected snackBarService: MatSnackBar,
    protected zoneService: PracticeCityZoneService,
    protected notifier: NotifierService
  ) {

  }


  // A dialóguson keresztül módosítani lehet a zóna nevét és képét
  // a szerverrel való kommunikációt is elvégzi a dialógus
  // undefined-al tér vissza a dialógus afterClosed observable-e, ha nem történt módosítás
  static openDialog(matDialogService: MatDialog, data: CityZoneDialogEditData): MatDialogRef<CityZoneDialogEditComponent, CityZoneDialogEditOutput> {
    return matDialogService.open(CityZoneDialogEditComponent, {
      width: '600px',
      data: data
    });
  }

  ngOnInit(): void {
    this.nameFC = new FormControl(this.data.zone.name, [Validators.required, CustomValidators.maximalLineCount(2)]);
    this.imageFC = new FormControl(this.data.zone.imageUrl ?? null);
  }


  public async handleSaveButtonClick(): Promise<void> {
    try {
      if (this.imageFC.value != null && this.imageFC.value != this.data.zone.imageUrl) {
        await this.zoneService.updateZoneImage(this.data.zone.cityUuid, this.data.zone.uuid, this.imageFC.value);
        this.notifier.notify('success', 'Kép mentése megtörtént');
      }
    } catch (error: any) {
      this.notifier.notify('error', 'Fájl feltöltés nem sikerült!');
      console.error(error);
    }

    const updatedZone = await this.zoneService.updateZoneProperties(this.data.zone.cityUuid, this.data.zone.uuid, { name: this.nameFC.value });
    this.notifier.notify('success','Adatok elmentve');
    this.dialogRef.close(updatedZone);
  }

  public async handleCancelButtonClick() {
    this.dialogRef.close();
  }

}

