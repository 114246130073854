import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpContext,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpParams,
  HttpRequest,
} from "@angular/common/http";
import { SessionService } from "./session.service";
import { Observable, Subscription } from "rxjs";
import { Settings, SettingsService } from "./settings.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { EnvironmentService } from "./environments/environment.service";

const sessionNotRequiredApis: ReadonlyArray<string> = ["admin/login"];

function isSessionRequired(apiUrlFragment: string): boolean {
  return !sessionNotRequiredApis.includes(apiUrlFragment);
}

@Injectable({
  providedIn: "root",
})
export class BackendService {
  public static readonly authorizationUuidHeaderName: string =
    "authorization-uuid";
  public static readonly authorizationTokenHeaderName: string =
    "authorization-token";

  constructor(
    private httpClient: HttpClient,
    private sessionService: SessionService,
    private settingsService: SettingsService,
    private environmentService:EnvironmentService,
    private ngxService: NgxUiLoaderService
  ) {}

  public async callApi<RequestBodyType = Object, ResponseBodyType = Object>(
    apiUrlFragment: string,
    httpRequestMethod: HttpRequestMethod,
    httpRequestBody?: RequestBodyType,
    filesWithNames?: ReadonlyMap<string, File|Array<File>>,
    callbacks?: HttpCallbacks<ResponseBodyType>,
    apiCallTimeout: number = 100000
  ): Promise<ResponseBodyType> {
    // Loader id kifejezetten ehhez a http kéréshez
    const randIdForLoader = "http" + Math.random().toString();
    let isDone = false;

    // Azért kell a setTimeout, hogy meghívódjon a change detector
    // egy hiba van a ngx ui loader lib-ben
    // https://github.com/t-ho/ngx-ui-loader/issues/45

    setTimeout(() => {
      // Miért is használunk randIdForLoader-t? Így pontosabb maybe?
      // Például ha lenne valahol egy hiba, ami miatt egy megjelenítés után 2x van hívva stopLoader
      // akkor ezt a http loadert kiütné. Ezt nem akarjuk, ha id-ra hivatkozunk akkor biztonságosabb a számlálás
      if (!isDone) this.ngxService.startBackground(randIdForLoader);
    });

    // Construct the HTTP request object
    const httpRequest: HttpRequest<RequestBodyType | FormData> =
      this.createHttpRequest(
        apiUrlFragment,
        httpRequestMethod,
        httpRequestBody,
        filesWithNames,
        callbacks,
        apiCallTimeout
      );

    if (
      this.settingsService.getSettingsValue<boolean>(Settings.apiLogging, false)
    ) {
      console.info(httpRequest);
    }

    // Create the HTTP event observable with the HTTP request
    const httpEventObservable: Observable<HttpEvent<ResponseBodyType>> =
      this.httpClient.request<ResponseBodyType>(httpRequest);

    try {
      const result = await this.createHttpResponsePromise(
        httpEventObservable,
        callbacks
      );

      this.ngxService.stopBackground(randIdForLoader);
      isDone = true;

      return result;
    } catch (rejectionReason: any) {
      this.ngxService.stopBackground(randIdForLoader);
      isDone = true;
      throw rejectionReason;
    }
  }

  private createHttpRequest<RequestBodyType, ResponseBodyType>(
    apiUrlFragment: string,
    httpRequestMethod: HttpRequestMethod,
    httpRequestBody?: RequestBodyType,
    filesWithNames?: ReadonlyMap<string, File|Array<File>>,
    callbacks?: HttpCallbacks<ResponseBodyType>,
    timeoutMs: number = 100000
  ): HttpRequest<RequestBodyType | FormData> {
    // Construct the API URL
    const apiUrl: string = this.environmentService.getCurrentEnvironment()!.serverHost + apiUrlFragment;

    // Construct the HTTP options (headers, should report progress, etc.)
    let httpHeaders: HttpHeaders = new HttpHeaders({});
    if (isSessionRequired(apiUrlFragment)) {
      httpHeaders = httpHeaders
        .set(
          BackendService.authorizationUuidHeaderName,
          this.sessionService.getUuid() ?? ""
        )
        .set(
          BackendService.authorizationTokenHeaderName,
          this.sessionService.getSessionId() ?? ""
        )
        .set("timeout", timeoutMs.toString());
    }

    const reportProgress: boolean =
      callbacks !== undefined &&
      (callbacks.onDownloadProgress !== undefined ||
        callbacks?.onUploadProgress !== undefined);
    const httpOptions: HttpOptions = {
      headers: httpHeaders,
      responseType: "json",
      reportProgress: reportProgress,
    };

    // Construct and return the HTTP request
    switch (httpRequestMethod) {
      case "DELETE":
      case "GET":
        return new HttpRequest<RequestBodyType>(
          httpRequestMethod,
          apiUrl,
          httpOptions
        );
      case "POST":
      case "PUT":
        if (filesWithNames !== undefined) {
          const formData: FormData = new FormData();

          if (httpRequestBody) {
            formData.append("data", JSON.stringify(httpRequestBody));
          }

          for (const [name, fileOrFiles] of filesWithNames) {
            const filesToAppend:Array<File> = fileOrFiles instanceof File ? [ fileOrFiles ] : fileOrFiles;
            for(const fileToAppend of filesToAppend) {
              formData.append(name, fileToAppend);
            }
          }

          return new HttpRequest<FormData>(
            httpRequestMethod,
            apiUrl,
            formData,
            httpOptions
          );
        } else {
          httpRequestBody ??= {} as RequestBodyType;
          return new HttpRequest<RequestBodyType>(
            httpRequestMethod,
            apiUrl,
            httpRequestBody,
            httpOptions
          );
        }
    }
  }

  private createHttpResponsePromise<ResponseBodyType>(
    httpEventObservable: Observable<HttpEvent<ResponseBodyType>>,
    callbacks?: HttpCallbacks<ResponseBodyType>
  ): Promise<ResponseBodyType> {
    return new Promise<ResponseBodyType>(
      (
        resolve: (
          value: ResponseBodyType | PromiseLike<ResponseBodyType>
        ) => void,
        reject: (reason: any) => void
      ) => {
        const httpEventSubscription: Subscription =
          httpEventObservable.subscribe(
            (httpEvent: HttpEvent<ResponseBodyType>) => {
              switch (httpEvent.type) {
                case HttpEventType.Sent:
                  callbacks?.onSent?.();
                  break;

                case HttpEventType.UploadProgress:
                  callbacks?.onUploadProgress?.(
                    httpEvent.loaded,
                    httpEvent.total
                  );
                  break;

                case HttpEventType.ResponseHeader:
                  callbacks?.onResponseHeader?.(httpEvent);
                  break;

                case HttpEventType.DownloadProgress:
                  callbacks?.onDownloadProgress?.(
                    httpEvent.loaded,
                    httpEvent.total
                  );
                  break;

                case HttpEventType.Response:
                  switch (httpEvent.status) {
                    case 200:
                    case 304:
                      const httpResponseBody: ResponseBodyType | null =
                        httpEvent.body;
                      if (httpResponseBody == null) {
                        reject("NO_BODY_IN_RESPONSE");
                        return;
                      }

                      if (httpResponseBody["error"]) {
                        reject({ error: httpResponseBody });
                        return;
                      }

                      resolve(httpResponseBody);
                      break;

                    default:
                      reject(httpEvent);
                  }
                  httpEventSubscription.unsubscribe();
                  break;

                default:
                // HttpEventType.User falls here -- nothing to do
              }
            },
            (httpResponseError: any) => {
              reject(httpResponseError);
            }
          );
      }
    );
  }
}

export type HttpRequestMethod = "DELETE" | "GET" | "POST" | "PUT";

export class HttpCallbacks<ResponseBodyType = any> {
  onSent?: () => void;
  onUploadProgress?: (loaded: number, total?: number) => void;
  onResponseHeader?: (httpEvent: HttpEvent<ResponseBodyType>) => void;
  onDownloadProgress?: (loaded: number, total?: number) => void;
}

class HttpOptions {
  headers?: HttpHeaders;
  context?: HttpContext;
  reportProgress?: boolean;
  params?: HttpParams;
  responseType?: "arraybuffer" | "blob" | "json" | "text";
  withCredentials?: boolean;
}
