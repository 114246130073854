import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotifierService } from 'angular-notifier';
import { BehaviorSubject } from 'rxjs';
import { awaitFor } from 'src/app/classes/misc';
import { VendorDocument } from 'src/app/classes/model/vendor';
import { ProgressDialogComponent } from 'src/app/modules/shared-module/components/progress-dialog/progress-dialog.component';
import { VendorService } from 'src/app/services/vendor.service';

export type AddVendorDocumentsDialogInput = {
  vendorUuid:string;
}

export type AddVendorDocumentsDialogOutput = { 
  documents: Array<VendorDocument>;
} 

@Component({
  selector: 'app-add-vendor-documents-dialog',
  templateUrl: './add-vendor-documents-dialog.component.html',
  styleUrls: ['./add-vendor-documents-dialog.component.scss']
})
export class AddVendorDocumentsDialogComponent implements OnInit {
  @ViewChild("fileInput", { static: true }) private fileInputElement:ElementRef<HTMLInputElement>;

  protected nameFC:FormControl<string>;

  protected pendingFiles:Array<File> = [];

  private maximumFileSizeInMb:number = 10;

  protected isUploadInProgress:boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data:AddVendorDocumentsDialogInput,
    private notifierService:NotifierService,
    private vendorService:VendorService,
    private dialogService:MatDialog,
    private dialogRef:MatDialogRef<AddVendorDocumentsDialogComponent, AddVendorDocumentsDialogOutput>
  ) { }

  /**
   * Opens the add vendor document dialog.
   * 
   * @param dialogService the dialog servce
   * @param data the dialog input (vendor uuid)
   * 
   * @returns refernece to the opened dialog
   */
  public static open(
    dialogService:MatDialog,
    data:AddVendorDocumentsDialogInput
  ):MatDialogRef<AddVendorDocumentsDialogComponent, AddVendorDocumentsDialogOutput> {
    return dialogService.open<
      AddVendorDocumentsDialogComponent,
      AddVendorDocumentsDialogInput,
      AddVendorDocumentsDialogOutput
    >(
      AddVendorDocumentsDialogComponent,
      {
        data: data,
        disableClose: true
      }
    );
  }

  public ngOnInit(): void {
    this.nameFC = new FormControl<string>("", [ Validators.required ]);

    this.fileInputElement.nativeElement.addEventListener("change", this.onFileInputChange);
  }

  private onFileInputChange = () => {
    // Get the files from the input element
    const files:FileList = this.fileInputElement.nativeElement.files;

    // Set the isAnyFileSizeExceededLimit to false by default
    let isAnyFileSizeExceededLimit:boolean = false;

    // Add the files to the pending list
    for(let index:number = 0; index < files.length; ++index) {
      const file:File = files.item(index);

      // Check if the file exceeds the size limit
      if(file.size > this.maximumFileSizeInMb * 1024 * 1024) {
        // Set the isAnyFileSizeExceededLimit to true
        isAnyFileSizeExceededLimit = true;
        // Move to the next file
        continue;
      }

      // Add the file to the pendig files
      this.pendingFiles.push(file);
    }

    // If any file exceeded the size limit, show a notification
    if(isAnyFileSizeExceededLimit) {
      this.notifierService.notify(
        "warning",
        `Egyes fájlok meghaladták a maximlis ${this.maximumFileSizeInMb} MB korlátot.`
      );
    }

  }

  /**
   * Handles the add files button click. It dispatches a click event to the hidden file
   * input element, which opens the file selector.
   */
  protected onAddFilesButtonClick():void {
    this.fileInputElement.nativeElement.click();
  }

  /**
   * Handles the upload button click. It uploads the actually selected files via the
   * corresponding API service and show a progress dialog about the upload's state.
   */
  protected async onUploadDocumentsButtonClick():Promise<void> {
    // Set the is upload in progress flag to true
    this.isUploadInProgress = true;

    // Create the progress and the text subject for the upload
    const progressSubject:BehaviorSubject<number> = new BehaviorSubject<number>(0);
    const textSubject:BehaviorSubject<string> = new BehaviorSubject<string>("Fájlok feltöltése a szerverre...");

    // Create the callback method for the upload progress
    const onUploadProgress:(loaded: number, total?: number) => void = (loaded: number, total?: number) => {
      const progress:number = total ? (loaded / total) : 0;
      progressSubject.next(progress);

      // If the upload is at 100%, we can assume that the saving to the S3 is happening
      if(progress === 1) {
        textSubject.next("Fájlok mentése S3-ba...")
      }
    }

    // Open the progress dialog
    const uploadProgressDialogRef = ProgressDialogComponent.open(this.dialogService, {
      progressSubject: progressSubject,
      textSubject: textSubject
    });

    let newVendorDocuments:AddVendorDocumentsDialogOutput = { documents: [] };

    console.log({V: this.data.vendorUuid,
      N: this.nameFC.value,
      P: this.pendingFiles});
    try {
      newVendorDocuments = await this.vendorService.uploadDocumentsToVendor(
        this.data.vendorUuid,
        this.nameFC.value,
        this.pendingFiles,
        onUploadProgress
      );
    } catch(error:any) {
      // If an error happened, log the error (the service will open a notification for now)
      console.error(error);
      return;
    } finally {
      // Close the upload progress dialog
      uploadProgressDialogRef.close();
      this.isUploadInProgress = false;
    }

    this.notifierService.notify("success", "Sikeres feltöltés!");
    this.dialogRef.close(newVendorDocuments)
  }

  protected onDeleteIconClick(index:number):void {
    this.pendingFiles.splice(index, 1);
  }


}
