import { ConfirmationDialogWithReasonComponent } from './../confirmation-dialog-with-reason/confirmation-dialog-with-reason.component';
import { ConfirmationDialogComponent } from './../../../../../../../modules/confirmation-dialog/components/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogComponentData } from './../../../../../../../modules/confirmation-dialog/models/confirmation-dialog-data';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { firstValueFrom } from 'rxjs';
import { awaitFor } from 'src/app/classes/misc';
import { PracticePathBaseInformations } from 'src/app/classes/model/practice-path';
import { PracticeVideoService } from 'src/app/services/practice-video.service';

// A komponens egy button clicket lekezelve
// be fogja zárni az összes megnyitott dialógust
// erre azért van szükség, mert a navigálás előtt az összes
// megnyitott dialógust be kell zárni, különben bugos lesz

@Component({
  selector: 'app-raw-video-tab',
  templateUrl: './raw-video-tab.component.html',
  styleUrls: ['./raw-video-tab.component.scss']
})
export class RawVideoTabComponent implements OnInit, AfterViewInit {
  @ViewChild("video", { static: false }) videoElement: ElementRef<HTMLVideoElement>;

  @Input() practicePath: PracticePathBaseInformations;
  @Output() anonymizationStartedEvent: EventEmitter<void>;

  anonymizationSettings: { fastMode: boolean, startNewInstance: boolean, debugBoundingBox: boolean } = {
    fastMode: true,
    startNewInstance: true,
    debugBoundingBox: false
  };

  isAnonymizationStarting: boolean = false;

  rawVideoDeletionInProgress: boolean = false;

  constructor(
    private practiceVideoService: PracticeVideoService,
    private notifierService: NotifierService,
    private routerService: Router,
    private activatedRoute: ActivatedRoute,
    private dialogService: MatDialog
  ) {
    this.anonymizationStartedEvent = new EventEmitter<void>();
  }

  ngOnInit(): void { }

  public ngAfterViewInit(): void {
    if (!this.hasAnyRawVideo()) {
      return;
    }

    this.videoElement.nativeElement.src = this.getVideoSource();
  }

  getVideoSource(): string {
    return this.practicePath.video.rawVideo?.videoUrl ?? this.practicePath.video.rawVideoConvertedMinimalBitrate?.minimalBitrateVideoUrl;
  }

  // eredeti vagy minimal bitrate
  public hasAnyRawVideo() {
    return !(this.practicePath.video.rawVideo == null && this.practicePath.video.rawVideoConvertedMinimalBitrate == null);
  }

  public hasOriginalRaw() {
    return this.practicePath.video.rawVideo != null;
  }

  public hasMinimalRaw() {
    return this.practicePath.video.rawVideoConvertedMinimalBitrate != null
  }

  public async anonymizeRawVideo(): Promise<void> {
    this.isAnonymizationStarting = true;

    try {
      await this.practiceVideoService.anonymizeVideo(this.practicePath, this.anonymizationSettings);
      this.anonymizationStartedEvent.emit();
    } catch (error: any) {
      console.error(error);
    }

    this.isAnonymizationStarting = false;
  }

  public async onDeleteRawVideoButtonClick(): Promise<void> {
    this.rawVideoDeletionInProgress = true;
    const dialog = ConfirmationDialogWithReasonComponent.openDialog(
      this.dialogService,
      {
        title: 'Biztosan törölni akarod a nyers videót? (minimal bitrate megmarad)',
        onNo: () => {
          dialog.close();
        },
        onYes: async (reason: string) => {
          dialog.close();

          this.rawVideoDeletionInProgress = true;

          try {
            await this.practiceVideoService.deleteRawVideo(this.practicePath, reason);
            this.videoElement.nativeElement.src = this.getVideoSource();
            this.notifierService.notify("success", "A videó sikeresen törölve lett");
          } catch (error: any) {
            console.error(error);
          }

          this.rawVideoDeletionInProgress = false;
        }
      });



  }

  /**
   * Closes all open dialogs (if there is any) and navigates to the main data edit page
   */
  protected async navigateToMainDataEdit(): Promise<void> {
    // If any dialog is open, close them
    this.dialogService.closeAll();
    // After all dialog is closed ...

    await firstValueFrom(this.dialogService.afterAllClosed);

    // Check we are on a different route than the main data edit page
    if (this.routerService.url.includes("main-data-edit") === false) {
      // If so, navigate to it
      try {
        await this.routerService.navigate(
          [this.practicePath.uuid, "main-data-edit"],
          {
            relativeTo: this.activatedRoute
          }
        );
      } catch (error: any) {
        console.error(error);
      }
    }
  }

}
