import { Injectable } from '@angular/core';
import { Vendor, VendorDocument } from '../classes/model/vendor';
import { HttpResponseData } from '../classes/http-communication';
import { BackendService } from './common/backend.service';
import { HttpErrorHandlerService } from './common/http-error-handler.service';
import { Package } from '../classes/model/packages/Package';

@Injectable({
  providedIn: 'root'
})
export class VendorService {
  private readonly vendorsApiUrlFragment:string = "/api/admin/vendors";
  private readonly vendorGiftingApiUrlFragment:string = "/api/admin/vendors/add_explicit_gift_to_vendor";
  private readonly vendorRegistrateApiUrlFragment:string = "/api/admin/vendors/registrate";
  private readonly documentFilesArrayName: string = "document-files";

  constructor(
    private backendService:BackendService,
    private httpErrorHandlerService:HttpErrorHandlerService
  ) { }


  public async fetchVendors():Promise<Array<Vendor>> {
    let vendors:Array<Vendor> = [];

    try {
      const response:HttpResponseData<Array<Vendor>> = await this.backendService.callApi(this.vendorsApiUrlFragment, "GET");
      vendors = response.data;
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a viszonteladók lekérdezése közben.");
    }

    return vendors;
  }

  public async createGiftForVendor(vendor:Vendor, packageDefinitionUuid:string, reason:string, message:string, quantity:number):Promise<void> {
    const httpBody:Object = {
      targetVendorUuid: vendor.uuid,
      packageDefinitionUuid: packageDefinitionUuid,
      reason: reason,
      message: message,
      quantity: quantity
    };

    try {
      const response:{ createdNewPackages:Array<Package> } = await this.backendService.callApi(this.vendorGiftingApiUrlFragment, "POST", httpBody);
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba az ajándék létrehozása közben");
    }

  }

  public async registrateVendor(vendorFields:Partial<Vendor>, canSendPaymentRequest:boolean = false, registrateAsClassroomInstructor:boolean = false) {
    const httpBody:Object = {
      vendorFields: vendorFields,
      canSendPaymentRequest: canSendPaymentRequest,
      registrateAsClassroomInstructor: registrateAsClassroomInstructor
    };

    try {
      await this.backendService.callApi(this.vendorRegistrateApiUrlFragment, "POST", httpBody);
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a viszonteladó regisztrációja közben.");
    }
  }

  public async modifyVendor(
    vendorUuid:string,
    phoneNumber:string,
    hasPaymentRequestPermission:boolean,
    hasContract:boolean
  ):Promise<Pick<Vendor, "phoneNumber"|"permissions"|"hasContract">> {
    const apiUrlPath:string = `${this.vendorsApiUrlFragment}/${vendorUuid}`;
    
    const httpBody = {
      phoneNumber: phoneNumber,
      hasPaymentRequestPermission: hasPaymentRequestPermission,
      hasContract: hasContract
    };

    try {
      const updatedVendorFields:Pick<Vendor, "phoneNumber"|"permissions"|"hasContract"> =
      await this.backendService.callApi(apiUrlPath, "POST", httpBody);

      return updatedVendorFields;
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a viszonteladó módosítása közben.");
    }
  }

  public async uploadDocumentsToVendor(
    vendorUuid:string,
    documentsName:string,
    files:Array<File>,
    onUploadProgress?:(loaded: number, total?: number) => void
  ):Promise<{ documents: Array<VendorDocument> }> {
    const apiUrlPath:string = `${this.vendorsApiUrlFragment}/${vendorUuid}/upload_documents`;

    const httpBody = { name: documentsName };

    const fileMap:Map<string, Array<File>> = new Map<string, Array<File>>();
    fileMap.set(this.documentFilesArrayName, files);

    try {
      const vendorDocuments:{ documents: Array<VendorDocument> } = await this.backendService.callApi(
        apiUrlPath,
        "POST",
        httpBody,
        fileMap,
        { onUploadProgress: onUploadProgress }
      );

      return vendorDocuments;
    } catch(error:any) {
      this.httpErrorHandlerService.handleError(error, "Hiba történt a dokumentumok feltöltése közben.");
    }
  }

  async fetchVendorPackages(vendorUuid: string): Promise<Array<Package>> {
    const apiUrlPathvendorsApiUrlFragment = `${this.vendorsApiUrlFragment}/${vendorUuid}/packages`;

    let packages: Array<Package> = [];

    try {
      const response: {packages: Array<Package>} = await this.backendService.callApi(apiUrlPathvendorsApiUrlFragment, "GET");
      packages = response.packages;

      return packages;
    } catch (error: any) {
      this.httpErrorHandlerService.handleError(error, "Hiba a viszonteladó csomagjainak lekérdezése közben.");
    }
  }

}
