import { Component, OnInit } from '@angular/core';
import { CategoryService } from 'src/app/services/category.service';
import { MatDialog } from '@angular/material/dialog';
import { Category } from 'src/app/classes/model/category';
import { CategoryEditDialogComponent } from './category-edit-dialog/category-edit-dialog.component';
import { DialogMode } from 'src/app/classes/misc';
import { QuestionGroup } from 'src/app/classes/model/question-groups';
import { QuestionGroupService } from 'src/app/services/questiongroup.service';

/**
 * @description The main component for the categories view. In the init state, the component fetch all categories (an question groups for them) from
 * the server.
*/
@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
  /** Array reference for the displayed categories. */
  categories:ReadonlyArray<Readonly<Category>>;
  /** Array reference for the question groups. */
  questionGroups:ReadonlyArray<QuestionGroup>;

  isContentLoading:boolean;

  constructor(
    public dialog:MatDialog,
    private categoryService:CategoryService,
    private questionGroupService:QuestionGroupService,
  ) { }

  ngOnInit(): void {
    this.questionGroups = this.questionGroupService.getQuestionGroupsArrayRef();
    this.categories = this.categoryService.getCategoriesArrayRef();
  }

  /**
   * Opens a dialog to add a new category to the categories.
   * 
   * @return {void} nothing
  */
  showAddCategoryDialog():void {
    this.dialog.open(CategoryEditDialogComponent, {
      data: {
        dialogMode: DialogMode.Add,
        category: null
      },
      disableClose: true
    });
  }

  public async handleReloadButtonClick():Promise<void> {
    try {
      await this.questionGroupService.loadDataIntoCache();
      await this.categoryService.loadDataIntoCache();
    } catch(error:any) {}
  }
}
