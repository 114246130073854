

import { WritableObservableArray } from "./../../../../../../../../modules/practice-path-video-editor-page/models/ObservableArray";
import { MarkerUtil } from "./../../../../../../../../modules/practice-path-video-editor-page/components/google-map-extended/marker-util";
import { GoogleMapExtendedController } from "./../../../../../../../../modules/practice-path-video-editor-page/components/google-map-extended/google-map-controller";
import { PracticePathService } from "src/app/services/practice-path.service";
import { NotifierService } from "angular-notifier";
import { MsToTimeStringPipe } from "./../../../../../../../../modules/practice-path-video-editor-page/pipes/ms-to-time-pipe";
import { PracticeCityBaseInformations } from "./../../../../../../../../classes/model/practice-city";
import { VideoPlayerController } from "src/app/modules/shared-module/components/video-player/video-player.controller";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Component, Inject, OnInit } from "@angular/core";
import { PracticePath } from "src/app/classes/model/practice-path";
import {
  MarkeredPolylineController,
  MarkeredPolylineOptions,
} from "src/app/modules/practice-path-video-editor-page/components/google-map-extended/markered-polyline-controller";
import { PracticePathUtil } from "src/app/services/practice-path-util";
import { LatLng } from "src/app/modules/practice-path-video-editor-page/models/LatLng";

export type PracticePathNodeTouchesEditorDialogData = {
  practicePath: PracticePath;
  city: PracticeCityBaseInformations
}

@Component({
  selector: "app-practice-path-node-touches-editor-dialog",
  templateUrl: "./practice-path-node-touches-editor-dialog.component.html",
  styleUrls: ["./practice-path-node-touches-editor-dialog.component.scss"],
})
export class PracticePathNodeTouchesEditorDialogComponent implements OnInit {
  videoPlayerController: VideoPlayerController;
  googleMapController: GoogleMapExtendedController;
  newNodeTouches: { touchMs: number; nodeUuid: string }[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    protected data: PracticePathNodeTouchesEditorDialogData,
    protected matDialogRef: MatDialogRef<PracticePathNodeTouchesEditorDialogComponent>,
    private notifier: NotifierService,
    private practicePathService: PracticePathService,
    private practicePathUtil: PracticePathUtil
  ) { }

  ngOnInit(): void {
    const videoUrl = this.getVideoUrl();
    if (videoUrl != null) {
      this.videoPlayerController = new VideoPlayerController(videoUrl);
    }

    this.newNodeTouches = JSON.parse(
      JSON.stringify(this.data.practicePath.nodeTouches ?? [])
    );

    const cityNodeUuids =
      this.data.city.cityNodeUuidsAssigmentToExamThesis[
      this.data.practicePath.examPathNumber
      ];
    // Ellenőrizzük, hogy a node uuid tömb megegyezik-e a newNodeTouches-al, ha nem akkor reseteljük
    if (cityNodeUuids.length != this.newNodeTouches.length) {
      this.newNodeTouches = this.createNodeTouchArrayFromNodeUuids(cityNodeUuids);
    } else {
      // Ellenőrizzük, hogy az i. indexen ugyanaz a node van-e
      for (let i = 0; i < cityNodeUuids.length; i++) {
        if (cityNodeUuids[i] != this.newNodeTouches[i].nodeUuid) {
          this.newNodeTouches =
            this.createNodeTouchArrayFromNodeUuids(cityNodeUuids);
          break;
        }
      }
    }
  }

  private createNodeTouchArrayFromNodeUuids(nodeUuids: string[]) {
    return nodeUuids.map((uuid) => ({ touchMs: 0, nodeUuid: uuid }));
  }

  onInitedGoogleMapController(controller: GoogleMapExtendedController) {
    this.googleMapController = controller;
    if (this.data.practicePath.path.length == 0) return;

    const markeredPolylineOptions: MarkeredPolylineOptions = {
      displayedName: "Sárga útvonal",
      id: "orange_path",
      isMultiSelectable: false,
      isDraggableMarkers: false,
      clickable: false,
      maximumVisibleMarkerOnMap: 250,
      zIndex: 0,
      polylineColor: "orange",
      optimized: true,
      showFirstAndLast: true,
      markerIconStyles: {
        globalStyle: MarkerUtil.getCircleMarker("orange", "orange"),
      },
    };

    const orangePathController = new MarkeredPolylineController(
      WritableObservableArray.fromPodArray(this.data.practicePath.path),
      markeredPolylineOptions
    );

    this.googleMapController.addMarkeredPolylineController(
      orangePathController
    );

    orangePathController.setMarkersVisibility(false);

    this.googleMapController.setCenter(this.data.practicePath.path[0].position);

    this.googleMapController.onTap.subscribe((posi: LatLng) => {
      const frame = this.practicePathUtil.getClosestGeoTaggedFrameToGeoLocation(
        this.data.practicePath.path,
        posi
      );
      this.videoPlayerController.seekTo(frame.millisecOfFrame);
    });
  }

  getVideoUrl(): string | null {
    if (this.data.practicePath.video.rawVideoConvertedMinimalBitrate != null) {
      return this.data.practicePath.video.rawVideoConvertedMinimalBitrate
        .minimalBitrateVideoUrl;
    }

    if (this.data.practicePath.video.releasedVideo != null) {
      return this.data.practicePath.video.releasedVideo.videoHlsUrl;
    }

    if (this.data.practicePath.video.rawVideo != null) {
      return this.data.practicePath.video.rawVideo.videoUrl;
    }

    return null;
  }

  getNodeFromUuid(nodeUuid: string) {
    return this.data.city.nodes.find((n) => n.uuid == nodeUuid);
  }

  getTouchMsForNode(indexInTouchArray: number): number | null {
    return this.newNodeTouches[indexInTouchArray]?.touchMs;
  }

  getTouchStringForNode(index: number) {
    const touchMs: number = this.getTouchMsForNode(index);
    if (touchMs == null) return "Hiba történt, info@mrkresz.hu címre írj!";
    return new MsToTimeStringPipe().transform(touchMs);
  }

  onTapSetCurrentTimeToNode(index: number) {
    const touch = this.newNodeTouches[index];
    const currentVideoPosition =
      this.videoPlayerController.getLastKnownPosition();

    touch.touchMs = currentVideoPosition;
  }

  async onTapSave() {
    try {
      const updatedPracticePath: PracticePath = await this.practicePathService.modifyPracticePath(
        this.data.practicePath.uuid,
        {
          editState: this.data.practicePath.editState,
          isFullPracticePath: this.data.practicePath.isFullPracticePath,
          name: this.data.practicePath.name,
          examPathNumber: this.data.practicePath.examPathNumber,
          nodeTouches: this.newNodeTouches,
          zoneUuids: this.data.practicePath.zoneUuids,
          isFree: this.data.practicePath.isFree
        }
      );
      this.notifier.notify("success", "Sikeres mentés!");
      this.matDialogRef.close(updatedPracticePath);
    } catch (e) {
      console.error(e);
      this.notifier.notify("error", "Sikertelen mentés");
    }
  }
}
