import { Component, Input, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { PracticePathNarrationDuringRecording } from 'src/app/classes/model/practice-path';
import { ResourceService } from 'src/app/services/common/resource.service';
import { NarrationPlayerDialogOpenerService } from '../../services/narration-player-dialog-opener.service';
@Component({
  selector: 'app-narrations-during-recording-card',
  templateUrl: './narrations-during-recording-card.component.html',
  styleUrls: ['./narrations-during-recording-card.component.scss']
})
export class NarrationsDuringRecordingCardComponent implements OnInit {
  @Input() narrationDuringRecording:PracticePathNarrationDuringRecording;

  isAudioDownloading:boolean = false;

  constructor(
    private narrationPlayerDialogOpenerService:NarrationPlayerDialogOpenerService,
    private resourceService:ResourceService,
    private notifierService:NotifierService
  ) { }

  ngOnInit(): void { }

  /**
   * Downloads the audio.
   */
  protected async downloadAudio():Promise<void> {
    // Set the audio is downloading to true
    this.isAudioDownloading = true;

    // Download the resource
    try {
      await this.resourceService.downloadResource(
        this.narrationDuringRecording.audioUrl,
        this.narrationDuringRecording.originalFileName,
        "audio/mp3"
      );
    } catch(error:any) {
      this.notifierService.notify("error", "Hiba a fájl letöltése közben.");
    }
    
    // Set the audio is downloading to false
    this.isAudioDownloading = false;
  }

  /**
   * Handles the open button click. It opens the (draggable) narration player dialog.
   */
  protected onOpenButtonClick():void {
    this.narrationPlayerDialogOpenerService.openNarrationPlayerDialog(this.narrationDuringRecording);
  }
}
