<div class="header">
  <div class="city-name-and-visibility">
    <span class="city-name">
      {{ practiceCity.city }}
    </span>
    <span *ngIf="!practiceCity.isVisible" class="material-icons">
      visibility_off
    </span>
  </div>
  <div class="actions">
    <app-icon-button
      matTooltip="Statisztika"
      [icon]="'assessment'"
      (click)="handleShowStatisticsButtonClick()"
    ></app-icon-button>
    <app-icon-button
      matTooltip="Vizsga érintési pontok sorrendje a vizsgaútvonalakban"
      *ngIf="isUserAuthorizedForChange"
      [icon]="'edit_road'"
      [disabled]="!isAdminContentResponsible()"
      (click)="openExamNumberNodeAssignmentEditorDialog()"
    ></app-icon-button>
    <app-icon-button
      matTooltip="Zónák"
      *ngIf="isUserAuthorizedForChange"
      [icon]="'pin_drop'"
      [disabled]="!isAdminContentResponsible()"
      (click)="handleZoneButtonClick()"
    ></app-icon-button>

    <app-icon-button
      matTooltip="Vizsga érintési pontok"
      *ngIf="isUserAuthorizedForChange"
      [icon]="'hub'"
      [disabled]="!isAdminContentResponsible()"
      (click)="handleEditNodesButtonClick()"
    ></app-icon-button>
    <app-icon-button
      matTooltip="Város módosítása"
      *ngIf="isUserAuthorizedForChange"
      [icon]="'edit'"
      [disabled]="!isAdminContentResponsible()"
      (click)="handleEditButtonClick()"
    ></app-icon-button>
    <app-icon-button
      *ngIf="isUserAuthorizedForChange"
      [icon]="'delete'"
      [color]="'red'"
      [disabled]="!isAdminContentResponsible()"
      (click)="handleDeleteButtonClick()"
    ></app-icon-button>
  </div>
</div>
<div class="metadata">
  <div class="responsibles">
    <div class="material-icons responsibles-icon">people</div>
    <div>
      {{ responsibles }}
      <span
        *ngIf="isUserAuthorizedForChange"
        class="material-icons edit-responsibles-icon"
        (click)="handleEditResponsiblesIconClick()"
      >
        edit
      </span>
    </div>
  </div>
</div>
<div class="footer">
  <div class="path-infos">
    <div>
      <span class="material-icons"> description </span>
      <span> {{ practiceCity.countOfExamPath }} tétel </span>
    </div>
    <div>
      <span class="material-icons"> pin_drop </span>
      <span> {{ practiceCity.nodes.length }} érintési pont </span>
    </div>
    <div>
      <span class="material-icons"> local_fire_department </span>
      <span> {{ practiceCity.criticalPoints.length }} kritikus pont </span>
    </div>
    <div>
      <span class="material-icons"> videocam </span>
      <span> {{ practiceCity.practicePathUuids.length }} videó </span>
    </div>
  </div>
  <div>
    <button
      mat-raised-button
      color="primary"
      [routerLink]="[practiceCity.uuid, 'paths']"
      [disabled]="!isAdminContentResponsible()"
    >
      Vizsgaútvonalak
    </button>
  </div>
</div>
