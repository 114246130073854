<!-- GUARD, InitState-től függően jelenítünk meg egy guardot -->
<!-- Ellenőrizzük, hogy: Van-e hozzáférése, tartalmaz-e megjeleníthető videót, létezik-e egyáltalán a videó -->
<div class="guard" *ngIf="this.initState != InitState.SUCCESS">
  <span *ngIf="initState == InitState.ERROR"> {{ errorMessageOnInit }} </span>
  <span *ngIf="initState == InitState.LOADING"> {{ "Betöltés..." }} </span>
  <span *ngIf="initState == InitState.NOT_EXIST_PATH">
    {{
      "Az útvonal nem létezik, próbáld meg megkeresni a vizsgaútvonalakban újra."
    }}
  </span>
  <span *ngIf="initState == InitState.NO_ACCESS">
    {{ "Nincs hozzáférésed a videóhoz" }}
  </span>
  <span *ngIf="initState == InitState.NO_VIDEO">
    {{ "Az útvonal nem tartalmaz megjeleníthető videót." }}
  </span>

  <mat-spinner
    *ngIf="initState == InitState.LOADING"
    style="margin: 0 auto"
  ></mat-spinner>
</div>

<!-- Editor -->
<div
  *ngIf="initState == this.InitState.SUCCESS"
  style="width: 100%; height: 100%; background: transparent"
>
  <as-split (dragEnd)="onSplitViewChange($event)" direction="horizontal">
    <!-- Bal oldali szerkesztő -->
    <as-split-area
      [size]="this.practiceSplitViewService.currentSettings.leftSide"
      [minSize]="25"
    >
      <div id="left-side">
        <div id="video-wrapper">
          <video-player
            [isKeyboardShortcutEnabled]="isKeyboardShortcutEnabled"
            [disabledControls]="['fullscreen']"
            [isControlEnabled]="true"
            [videoPlayerController]="
              this.globalPracticePathEditorService.mainVideoPlayerController
            "
          >
            <video-overlay
              *ngIf="
                this.activatedStopPoint != null &&
                this.activatedStopPoint.stopPoint?.focusSettings != null
              "
            >
              <focus-video-overlay
                [relativeRadiusToHeight]="
                  this.activatedStopPoint.stopPoint.focusSettings.radius
                "
                [relativeCenterYToHeight]="
                  this.activatedStopPoint.stopPoint.focusSettings.y
                "
                [relativeCenterXToWidth]="
                  this.activatedStopPoint.stopPoint.focusSettings.x
                "
              >
              </focus-video-overlay>
            </video-overlay>

            <video-overlay *ngIf="this.activatedStopPoint != null">
              <app-text-video-overlay
                [positionBottom]="'46px'"
                [text]="this.activatedStopPoint.criticalPoint.description"
                [title]="
                  this.globalPracticePathEditorService.getAssignmentIndex(
                    this.activatedStopPoint.criticalPoint.uuid
                  ) +
                  1 +
                  '. ' +
                  this.activatedStopPoint.criticalPoint.title
                "
              >
              </app-text-video-overlay>
            </video-overlay>

            <video-overlay>
              <app-icon-overlay
                [iconsAlignment]="'start'"
                [iconContainerPosition]="'left'"
                [iconUrls]="this.displayedLeftTableIconsUrl"
              >
              </app-icon-overlay>
            </video-overlay>

            <video-overlay>
              <app-icon-overlay
                [iconsAlignment]="'center'"
                [iconContainerPosition]="'top'"
                [iconUrls]="this.displayedTopIndexIconsUrl"
              >
              </app-icon-overlay>
            </video-overlay>
          </video-player>
        </div>

        <!-- Térkép -->
        <div style="position: relative" id="map-container">
          <google-map-extended (onReady)="onMapInited($event)">
          </google-map-extended>

          <!-- Autó követése gomb -->
          <div
            style="
              background-color: white;
              padding: 8px;
              border-radius: 2px;
              box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
              position: absolute;
              right: 64px;
              top: 10px;
            "
          >
            <app-follow-single-marker-controller-button
              [singleMarkerController]="carMarkerController"
            ></app-follow-single-marker-controller-button>
          </div>

          <!-- Sárga pöttyök mentése gomb -->

          <button
            *ngIf="this.markeredPolylineControllerForPath?.isMarkerVisible"
            style="
              color: white;
              background-color: 'orange';
              position: absolute;
              right: 135px;
              top: 11px;
            "
            mat-raised-button
            (click)="onTapSaveOrangePathPoints()"
          >
            Útvonal pontok mentése
          </button>
        </div>
      </div>
    </as-split-area>

    <!-- Jobb oldali szerkesztő -->
    <as-split-area
      [size]="this.practiceSplitViewService.currentSettings.rightSide"
      [minSize]="25"
    >
      <div id="right-side">
        <mat-tab-group
          style="height: 100%"
          (selectedTabChange)="selectedMatTabChanged($event)"
          mat-align-tabs="start"
          [selectedIndex]="0"
          mat-stretch-tabs="false"
          mat-align-tabs="start"
          headerPosition="above"
        >
          <mat-tab label="Kritikus pont 💥">
            <app-critical-points-tab
              [originalTabIndex]="1"
            ></app-critical-points-tab>
          </mat-tab>

          <mat-tab label="Időzített ikon ⌚️">
            <app-timed-icons-tab [originalTabIndex]="2"> </app-timed-icons-tab>
          </mat-tab>

          <mat-tab label="Útvonal ikon 📍">
            <app-path-icons-tab [originalTabIndex]="3"> </app-path-icons-tab>
          </mat-tab>

          <mat-tab label="ℹ️">
            <app-other-info-tab></app-other-info-tab>
          </mat-tab>
        </mat-tab-group>
      </div>
    </as-split-area>
  </as-split>
</div>
