<div class = "title">
    {{ title }}
</div>
<div>
    <form style = "display: grid; grid-template-columns: auto 100px; gap: 20px;">
        <div>
             <mat-form-field appearance='outline' style = "width: 100%;">
                <mat-label>Vizsga érintési pont neve</mat-label>
                <textarea matInput [formControl] = "nodeNameFC" class = "node-name-textarea"></textarea>
            </mat-form-field>
        </div>
        <div style = "align-self: center;">
            <button
                mat-raised-button
                [disabled] = "!nodeNameFC.value || nodeNameFC.hasError('maximalLineCount') || nodeCreationLoading"
                (click) = "addNodeToCity()"
            >
                Felvesz
            </button>
        </div>
    </form>
</div>
<div class = "node-items-container">
    <ng-container *ngIf="data.practiceCity.nodes.length; then nodeList; else noNodes">
    </ng-container>

    <ng-template #nodeList>
        <div *ngFor = "let node of data.practiceCity.nodes; let i = index" class = "node-item">
            <div>
                <div *ngIf = "node.imageUrl" class = "node-image" [ngStyle] = "{'background-image': 'url(' + node.imageUrl + ')'}">

                </div>
                <div *ngIf = "!node.imageUrl" class = "node-no-image">
                    <span class = "material-icons">
                        no_photography
                    </span>
                </div>
            </div>
            <div class = "node-data">
                <div class = "node-item-first-row">
                    <span style = "font-weight: 500; font-size: 1.2em;">
                        {{ node.name }}
                    </span>
                    <span class = "material-icons action" (click) = "editNode(i)">
                        edit
                    </span>
                    <span class = "material-icons action" (click) = "deleteNodeFromCity(i)">
                        delete
                    </span>
                </div>
                <div class = "node-item-bottom-row">
                    <mat-chip-listbox [selectable] = "false">

                        <mat-chip-option
                            *ngIf = "node.blurhashOfImage != null"
                            (click)="snackBarService.open('blurhash kimásolva','Bezár', { duration: 2000 })"
                            [cdkCopyToClipboard]="node.blurhashOfImage"
                            [disableRipple] = "true"> 🌌 Blurhash
                        </mat-chip-option>
                    </mat-chip-listbox>
                </div>
            </div>
        </div>
    </ng-template>

      <ng-template #noNodes>
        <div class = "no-nodes">
            A város még nem rendelkezik egyetlen érintési ponttal sem.
        </div>
      </ng-template>


</div>
<mat-dialog-actions align = "end">
    <button mat-raised-button mat-dialog-close> Bezár </button>
</mat-dialog-actions>
