import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Permission } from 'src/app/classes/model/permissions';
import { PermissionService } from 'src/app/services/common/permission.service';
import { Vendor } from '../../../../../classes/model/vendor';
import { VendorService } from '../../../../../services/vendor.service';
import { CreateVendorDialogComponent } from './create-vendor-dialog/create-vendor-dialog.component';
import { VendorDetailsDialogComponent } from './vendor-details-dialog/vendor-details-dialog.component';

@Component({
  selector: 'app-vendors',
  templateUrl: './vendors.component.html',
  styleUrls: ['./vendors.component.scss']
})
export class VendorsComponent implements OnInit {

  vendors:ReadonlyArray<Vendor> = [];

  isContentLoading:boolean = false;

  constructor(
    private vendorService:VendorService,
    private dialogService:MatDialog,
    private permissionService:PermissionService
  ) { }

  public ngOnInit():void {
    // Load the vendors
    this.loadVendors();
  }

  /**
   * Loads the actual vendors via the `vendorService`.
   */
  private async loadVendors():Promise<void> {
    // Set the content loading to true
    this.isContentLoading = true;

    try {
      // Fetch the vendors
      this.vendors = await this.vendorService.fetchVendors();
    } catch(error:any) {}

    // Set the content loading to false
    this.isContentLoading = false;
  }

  public async openVendorInfoDialog(vendor:Vendor):Promise<void> {
    const packages = await this.vendorService.fetchVendorPackages(vendor.uuid);

    VendorDetailsDialogComponent.open(
      this.dialogService,
      { vendor: vendor, vendorPackages: packages }
    );
  }

  /**
   * Handles the add button click, it opens a vendor creator dialog.
   */
  public onAddVendorButtonClick():void {
    const dialogRef:MatDialogRef<CreateVendorDialogComponent, boolean> = CreateVendorDialogComponent.open(this.dialogService);

    dialogRef.afterClosed().subscribe(
      async (dialogResult:boolean) => {
        if(dialogResult) {
          await this.loadVendors();
        }
      }
    );
  }

  /**
   * Determines that the current admin user has the vendor write permission.
   * 
   * @returns true if the admin has the permission, false otherwise
   */
  protected hasAdminVendorWritePermission():boolean {
    return this.permissionService.isLoggedUserHasPermission(Permission.VendorWrite);
  }

}
