<app-page-header
    title = "Új vizsgaútvonal létrehozása"
    style = "margin-bottom: 20px;"
></app-page-header>

<ng-container
    *ngIf = "initState === InitState.Success;
    then initSuccess;
    else loadingOrInitError"
></ng-container>

<!-- Guard for error and loading -->
<ng-template #loadingOrInitError>
  <div [ngSwitch]="initState">
    <div *ngSwitchCase = "InitState.Loading" style = "padding-top: 20px;">
      <mat-spinner style = "margin: 0 auto;"></mat-spinner>
      <div style = "text-align: center; margin-top: 20px;">
        Város adatok betöltése
      </div>
    </div>
    <div *ngSwitchCase = "InitState.Error">
        Hiba a városadatok betöltése közben. Kattints az alábbi gombra az újrapróbáláshoz:
        <div style = "margin-top: 10px;">
          <button
            mat-raised-button
            color = "primary"
            (click) = "loadPracticeCityBaseInformations()"
          >
            <mat-icon> refresh </mat-icon>
            Újratöltés
          </button>
        </div>
    </div>
  </div>
</ng-template>

<!-- Content -->
<ng-template #initSuccess>
    <app-practice-path-main-data-editor
        #practicePathEditor
        [practiceCitiesBaseInformations] = "practiceCitiesBaseInformations"
        [defaultSelectedCityUuid] = "cityUuid"
    ></app-practice-path-main-data-editor>

    <button
        style = "margin-top:20px;margin-bottom:20px;"
        (click)="onTapAddNewPracticePath()"
        mat-raised-button
    >
        Új útvonal létrehozása
    </button>
</ng-template>
