import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CriticalPointCacherService } from 'src/app/services/cacher-services/critical-point-cacher.service';
import { PracticePathCacherService } from 'src/app/services/cacher-services/practice-path-cacher.service';
import { CachedCriticalPointFields, CachedPracticePath } from '../../../models/cached-entities.model';
import { CriticalPointAssignmentLog } from '../../../models/practical-module-log.model';
import { getDisplayedEntityAndEventName } from '../../../models/practice-module-event-log-groups.model';
import { PracticalModuleLogsDataService } from '../../../services/practical-module-logs-data.service';
import { EntityCachingState, PracticalModuleLogsEntityCacherHelperService } from '../../../services/practical-module-logs-entity-cacher-helper.servce';
import { PracticalModuleLogsDialogComponent } from '../../practical-module-logs-dialog/practical-module-logs-dialog.component';

@Component({
  selector: 'app-critical-point-assignment-log-item-header',
  templateUrl: './critical-point-assignment-log-item-header.component.html',
  styleUrls: ['./critical-point-assignment-log-item-header.component.scss']
})
export class CriticalPointAssignmentLogItemHeaderComponent implements OnInit {
  @Input() criticalPointAssignmentLog:CriticalPointAssignmentLog;
  
  protected displayedEntityAndEventName:string;

  protected EntityCachingState = EntityCachingState;

  protected practicePathCachingState:EntityCachingState;
  protected criticalPointCachingState:EntityCachingState;

  protected practicePathName:string|null;
  protected criticalPointTitle:string|null;
  protected criticalPointDescription:string|null;

  constructor(
    private practicalModuleLogsDataService:PracticalModuleLogsDataService,
    private dialogService:MatDialog,
    private practicePathCacherService:PracticePathCacherService,
    private criticalPointCacherService:CriticalPointCacherService,
    private practicalModuleLogsEntityCacherHelperService:PracticalModuleLogsEntityCacherHelperService
  ) { }

  ngOnInit(): void {
    // Get the displayed entity name and operation name
    this.displayedEntityAndEventName = getDisplayedEntityAndEventName(this.criticalPointAssignmentLog);

    // Get the related practice path from the caching service
    this.practicePathCachingState = this.practicalModuleLogsEntityCacherHelperService.getPracticePathCachingState(
      this.criticalPointAssignmentLog.practicePathUuid
    );

    if(this.practicePathCachingState === EntityCachingState.CachedAndExist) {
      const cachedPracticePath:CachedPracticePath|null = this.practicePathCacherService.getPracticePathFromCache(
        this.criticalPointAssignmentLog.practicePathUuid
      ).practicePath as CachedPracticePath;

      this.practicePathName = cachedPracticePath.name;
    }

    // Get the critical point from the caching service
    this.criticalPointCachingState = this.practicalModuleLogsEntityCacherHelperService.getCriticalPointCachingState(
      this.criticalPointAssignmentLog.criticalPointUuid
    );

    if(this.criticalPointCachingState === EntityCachingState.CachedAndExist) {
      const criticalPoint:CachedCriticalPointFields = this.criticalPointCacherService.getCriticalPointFromCache(
        this.criticalPointAssignmentLog.criticalPointUuid
      ).criticalPoint;

      this.criticalPointTitle = criticalPoint.title;
      this.criticalPointDescription = criticalPoint.description;
    }
  }

  /**
   * Determines if the provided entity key is the same as the entity which logs is currently displayed.
   * 
   * @returns true if the entity is the same, false otherwise
   */
  protected isLogEntitySameAsOpenedLoggedEntity(entityUuid:string):boolean {
    return this.practicalModuleLogsDataService.getEntityKeyAsString() === entityUuid;
  }

  /**
   * Opens the log's critical point's logs in a new dialog.  
   */
  protected openCriticalPointLogsInDialog():void {
    if(this.isLogEntitySameAsOpenedLoggedEntity(this.criticalPointAssignmentLog.criticalPointUuid)) {
      return;
    }

    PracticalModuleLogsDialogComponent.open(
      this.dialogService,
      {
        entityType: "critical_point",
        entityKey: this.criticalPointAssignmentLog.criticalPointUuid,
        dialogTitle: "Kritikus pont logok"
      }
    );
  }

  /**
   * Opens the log's practice path's logs in a new dialog.  
   */
  protected openPracticePathLogsInDialog():void {
    if(this.isLogEntitySameAsOpenedLoggedEntity(this.criticalPointAssignmentLog.practicePathUuid)) {
      return;
    }

    PracticalModuleLogsDialogComponent.open(
      this.dialogService,
      {
        entityType: "practice_path",
        entityKey: this.criticalPointAssignmentLog.practicePathUuid,
        dialogTitle: "Vizsgaútvonal logok"
      }
    );
  }

}
