import { DeviceInfo } from './device';
import { Package } from './packages/Package';

export class StudentPurchaseLog {
    productId:string;
    purchaseDate:number;
}

export class StudentFillout {
    uuid:string;
    categoryUuid:string;
    categoryName:string;
    fillDateTimeStamp:number;
    isSuccess:boolean;
    minPoint:number;
    maxPoint:number;
    score:number;
    type?:string; // Doesn't exist in the old version
}

export class CreditSpentLogItem {
    timestamp:number;
    credit:number;
    productName:string;
}

export class RedeemedCodesItem {
    code:string;
    comment:string;
    credit:number;
    redeemedOn:number;
}

export class Student {
    uuid:string;
    email:string;
    firstName:string;
    lastName:string;
    fullname:string;
    birthdayYear:number|"";
    city:string;
    profileImageUrl:string;
    registrationDate:number;
    registratedWith:string;
    lastLoginDate:number;
    gender:string;
    histories:Array<StudentFillout>;
    purchaseLogs:Array<StudentPurchaseLog>;
    pendingExplicitGiftedPackage:Array<any>;
    gifts:Array<any>;
    credit:number;
    devices:Array<DeviceInfo>;
    creditSpentLogs:Array<CreditSpentLogItem>;
    redeemedCodes:Array<RedeemedCodesItem>;
    customerId:CustomerId;
    creditedPackageLogs:Array<CreditedPackageLogItem>;
    digitalAccesses:Array<DigitalAccessItem>

    constructor() {
        this.histories = new Array<StudentFillout>();
        this.purchaseLogs = new Array<StudentPurchaseLog>();
        this.pendingExplicitGiftedPackage = new Array<any>();
        this.creditSpentLogs = new Array<CreditSpentLogItem>();
        this.redeemedCodes = new Array<RedeemedCodesItem>();
        this.creditedPackageLogs = new Array<CreditedPackageLogItem>();
        this.digitalAccesses = new Array<DigitalAccessItem>();
    }
}

export function setUnsettedValuesInStudent(student:Student):Student {
    student.email ??= "";
    student.lastName ??= "";
    student.firstName ??= "";
    student.city ??= "";
    student.gender ??= "";
    student.birthdayYear ??= "";
    student.purchaseLogs ??= [];
    student.pendingExplicitGiftedPackage ??= [];
    student.creditSpentLogs ??= [];
    student.redeemedCodes ??= [];
    student.creditedPackageLogs ??= [];
    student.digitalAccesses ??= [];

    student.fullname = [ student.lastName ?? "", student.firstName ?? "" ].filter(
        (nameFragment:string) => { return nameFragment !== ""; }
    ).join(" ");

    return student;
}

export function studentSorter(student1:Student, student2:Student) {
    return student1.fullname.localeCompare(student2.fullname);
}

export function clearStudentPersonalDetails(student:Student):Student {
    student.lastName = "";
    student.firstName = "";
    student.fullname = "";
    student.city = "";
    student.gender = "";
    student.birthdayYear = "";
    student.profileImageUrl = "";

    return student
}

export type CustomerId = {
    id:string,
    studentEmail:string,
    studentOwnerUuid:string,
    studentName:string
}

export type CreditedPackageLogItem = {
    creditedPackage:Package;
    creditingTimestamp:number;
}

export type DigitalAccessItem = {
    accessName:string;
    expireInTimestamp:number;
}