<div class="main-row">
  <!-- Name filter -->
   <mat-form-field appearance='outline' style="width:300px;">
    <mat-label>Név vagy uuid tartalmazza</mat-label>
    <input matInput [formControl]="nameOrUuidFilterFC" />
  </mat-form-field>

  <!-- Edit state filter -->
   <mat-form-field appearance='outline' style="width: 250px;">
    <mat-label>Állapot</mat-label>
    <mat-select [formControl]="editStateFilterFC">
      <mat-option [value]="'*'"> Összes </mat-option>
      <mat-option
        *ngFor="let editState of pathEditStates"
        [value]="editState.key"
      >
        <div style="display: flex; align-items: center; gap: 5px">
          <span
            class="state-color-indicator"
            [ngStyle]="{ 'background-color': editState.color }"
          >
            <!-- State color goes here -->
          </span>
          <span>
            {{ editState.displayedName }}
          </span>
        </div>
      </mat-option>
    </mat-select>
  </mat-form-field>

  <!-- City filter -->
   <mat-form-field appearance='outline' style="width: 250px;">
    <mat-label>Város</mat-label>
    <mat-select [formControl]="cityFilterFC">
      <mat-option
        *ngFor="
          let practiceCityNameAndResponsibles of practicePathsPageDataService.practiceCityNamesAndResponsibles$
            | async
        "
        [value]="practiceCityNameAndResponsibles.uuid"
        [disabled]="!isAdminResposibleOfCity(practiceCityNameAndResponsibles)"
      >
        {{ practiceCityNameAndResponsibles.city }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div class="flex-break-new-line">
  </div>

  <!-- Node filter -->
   <mat-form-field appearance='outline' style="width: 300px;">
    <mat-label>Vizsga érintési pont (teljes útvonal esetén)</mat-label>
    <mat-select [formControl]="nodeFilterFC">
      <mat-select-trigger>
        <div style="display: flex; justify-content: space-between">
          {{ getSelectedNodeName() }}
          <mat-spinner
            *ngIf="practicePathsPageDataService.selectedCityLoading$ | async"
            [diameter]="18"
            [strokeWidth]="3"
          ></mat-spinner>
        </div>
      </mat-select-trigger>
      <mat-option [value]="'*'"> Összes </mat-option>
      <mat-option
        *ngFor="
          let node of practicePathsPageDataService.getSelectedCity()?.nodes
        "
        [value]="node.uuid"
      >
        {{ node.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>


  <!-- Zone filter -->
   <mat-form-field appearance='outline' style="width: 250px;">
    <mat-label>Zóna</mat-label>
    <mat-select [formControl]="zoneFilterFC">
      <mat-select-trigger>
        <div style="display: flex; justify-content: space-between">
          {{ getSelectedZoneName() }}
          <mat-spinner
            *ngIf="practicePathsPageDataService.selectedCityLoading$ | async"
            [diameter]="18"
            [strokeWidth]="3"
          ></mat-spinner>
        </div>
      </mat-select-trigger>
      <mat-option [value]="'*'"> Összes </mat-option>
      <mat-option
        *ngFor="
          let zone of practicePathsPageDataService.getSelectedCity()?.zones
        "
        [value]="zone.uuid"
      >
        {{ zone.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div class="checkbox-row">
  <mat-checkbox [formControl]="onlyFullPracticePathsFC">
    <span style="white-space: nowrap;">
      Teljes vizsgaútvonalak mutatása
    </span>
  </mat-checkbox>
  <mat-checkbox [formControl]="onlyWithNoReleasedVideoFC">
    <span style="white-space: nowrap;">
      Csak kiadott videóval nem rendelkezőek
    </span>
  </mat-checkbox>
  <mat-checkbox [formControl]="onlyFreePracticePathsFC">
    <span style="white-space: nowrap;">
      Csak ingyenes útvonalak
    </span>
  </mat-checkbox>
</div>
