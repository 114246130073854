import { FormGroup, Validators } from "@angular/forms";
import { PracticeCityBaseInformations } from "./../../../../../../../../classes/model/practice-city";
import {
  PracticePath,
  DisplayedPathEditState,
  pathEditStates,
  PathEditState,
  CriticalPointAssignment,
  StopPoint,
} from "./../../../../../../../../classes/model/practice-path";
import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { NotifierService } from "angular-notifier";

export type PracticePathDataEditorResult = {
  practicePathName: string;
  editState: string;
  cityUuid: string;
  isFullPracticePath: boolean;
  examPathNumber?: number;
  zoneUuids: string[],
  isFree: boolean
};

@Component({
  selector: "app-practice-path-main-data-editor",
  templateUrl: "./practice-path-main-data-editor.component.html",
  styleUrls: ["./practice-path-main-data-editor.component.scss"],
})
export class PracticePathMainDataEditorComponent implements OnInit, OnChanges {
  @Input()
  practicePath: PracticePath;

  @Input()
  practiceCitiesBaseInformations:PracticeCityBaseInformations[] = [];

  @Input()
  defaultSelectedCityUuid?:string;

  currentSelectedCity?:PracticeCityBaseInformations;

  // Form controllers
  cityFC: FormControl;
  nameFC: FormControl;
  editStateFC: FormControl;
  zonesFC: FormControl;
  isFreeFC: FormControl;


  isFullExamPathFc: FormControl;
  examPathNumberFc: FormControl;

  formGroup: FormGroup;

  cityNames: string[];
  cityUuids: string[];

  pathEditStates: ReadonlyArray<DisplayedPathEditState>;

  lastPathEditState:PathEditState;

  constructor(
    private notifierService:NotifierService
  ) {}

  ngOnChanges() {
    if (this.formGroup == null) return;
    this.initFormControllersFromInitPath();
  }

  ngOnInit(): void {
    this.initDefaultFormControllers();

    if (this.practicePath != null) {
      // Van biztosítva default path, tehát egy létező practice path adatait szerkesztjük
      this.initFormControllersFromInitPath();
    }

    // Set the current selected city's inital value to the city form control's value
    this.setCurrentSelectedCity(this.cityFC.value);

    this.addChangeListeners();
  }

  addChangeListeners() {
    this.cityFC.valueChanges.subscribe((cityUuid) => {
      this.isFullExamPathFc.setValue(false);
      this.setCurrentSelectedCity(cityUuid);
    });

    this.isFullExamPathFc.valueChanges.subscribe((newValue: boolean) => {
      if (!newValue) {
        this.examPathNumberFc.setValue(null);
        this.examPathNumberFc.disable();
      } else {
        this.examPathNumberFc.enable();
      }
    });

    this.editStateFC.valueChanges.subscribe(
      (newValue:PathEditState) => {
        if(newValue === "DONE" && this.hasAllCriticalPointStoppingPoint() === false) {
          this.notifierService.notify(
            "error",
            "Az útvonal nem állítható készre, mert vannak kritikus pontok megállási pont nélkül!"
          );
          this.editStateFC.setValue(this.lastPathEditState, { emitEvent: false });
        } else {
          this.lastPathEditState = newValue;
        }
      }
    );
  }

  initDefaultFormControllers() {
    this.cityNames = this.practiceCitiesBaseInformations.map((pc) => pc.city);
    this.cityUuids = this.practiceCitiesBaseInformations.map((pc) => pc.uuid);

    this.pathEditStates = pathEditStates;

    const initPathEditState: PathEditState = "UPLOADED";

    this.nameFC = new FormControl("", [Validators.required]);
    this.cityFC = new FormControl(
      this.defaultSelectedCityUuid ?? "",
      [Validators.required]
    );

    this.isFreeFC = new FormControl(this.practicePath?.isFree ?? false);

    this.editStateFC = new FormControl(initPathEditState, [
      Validators.required,
    ]);

    this.zonesFC = new FormControl(this.practicePath?.zoneUuids ?? [], []);

    this.isFullExamPathFc = new FormControl(false, [Validators.required]);

    this.examPathNumberFc = new FormControl(null, [Validators.required]);
    // The exam path number form control is do
    this.examPathNumberFc.disable();

    this.formGroup = new FormGroup({
      cityFC: this.cityFC,
      nameFC: this.nameFC,
      editStateFC: this.editStateFC,
      zonesFC: this.zonesFC,
      isFreeFC: this.isFreeFC,
      isFullExamPathFC: this.isFullExamPathFc,
      examPathNumberFC: this.examPathNumberFc,
    });
  }

  getCurrentPathEditStateColor() {
    if (this.editStateFC.value == null || this.editStateFC.value == "")
      return "black";
    const c = pathEditStates.find((p) => p.key == this.editStateFC.value).color;
    return c;
  }

  initFormControllersFromInitPath() {
    if (this.practicePath == null) return;

    this.currentSelectedCity = this.practiceCitiesBaseInformations.find(
      (c) => c.uuid == this.practicePath.cityUuid
    );

    this.lastPathEditState = this.practicePath.editState;

    this.nameFC.setValue(this.practicePath.name);
    this.cityFC.setValue(this.practicePath.cityUuid);

    this.editStateFC.setValue(this.practicePath.editState, { emitEvent: false });
    this.isFullExamPathFc.setValue(this.practicePath.isFullPracticePath);

    if (this.practicePath.examPathNumber != null) {
      this.examPathNumberFc.setValue(this.practicePath.examPathNumber);
    }

    if (this.practicePath.isFullPracticePath) {
      this.examPathNumberFc.enable();
    } else {
      this.examPathNumberFc.setValue(null);
      this.examPathNumberFc.disable();
    }
  }

  // A formokból összeállít egy editor result-ot
  getDataFromForm(): PracticePathDataEditorResult {
    const data = {
      editState: this.editStateFC.value,
      cityUuid: this.cityFC.value,
      practicePathName: this.nameFC.value,
      isFullPracticePath: this.isFullExamPathFc.value,
      examPathNumber: this.examPathNumberFc.value,
      zoneUuids: this.zonesFC.value,
      isFree: this.isFreeFC.value
    };
    for (const key in data) {
      if (data[key] == null) {
        delete data[key];
      }
    }
    return data;
  }

  isValidForm() {
    this.formGroup.markAllAsTouched();
    return this.formGroup.valid;
  }

  private hasAllCriticalPointStoppingPoint():boolean {
    // Returns true if there is no critical point assignment with at least one active stopping point
    return this.practicePath.criticalPointAssignments.every(
      (criticalPointAssignment:CriticalPointAssignment) => {
        // Returns true if there is no active stopping point in the array
        return criticalPointAssignment.stopPoints.some(
          (stopPoint:StopPoint) => {
            return stopPoint.isActive === true;
          }
        );
      }
    );
  }

  private setCurrentSelectedCity(cityUuid:string):void {
    this.currentSelectedCity = this.practiceCitiesBaseInformations.find(
      (pc) => pc.uuid == cityUuid
    );
  }

  protected getSelectedEditState():DisplayedPathEditState|null {
    return this.pathEditStates.find(
      (editState:DisplayedPathEditState) => { return editState.key === this.editStateFC.value }
    ) ?? null;
  }

}
