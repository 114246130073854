<!-- Entity and event name -->
<div style="font-weight: 500;">
  {{ displayedEntityAndEventName }}
</div>

<!-- Practice path -->
<div style="font-size: 0.9rem;">
  <div [ngSwitch]="practicePathCachingState" style="font-size: 0.9rem;">
    <div *ngSwitchCase="EntityCachingState.CachedAndExist" (click)="openPracticePathLogsInDialog()"
      [class.clickable-text]="isLogEntitySameAsOpenedLoggedEntity(criticalPointAssignmentLog.practicePathUuid) == false">
      📹 {{ practicePathName }}
    </div>

    <div *ngSwitchCase="EntityCachingState.CachedAndNotExist" style="font-style: italic;">
      <div>
        Nem létező vizsgaútvonal (valószínűleg törölve)
      </div>
      <div (click)="openPracticePathLogsInDialog()"
        [class.clickable-text]="isLogEntitySameAsOpenedLoggedEntity(criticalPointAssignmentLog.practicePathUuid) == false">
        📹 {{ criticalPointAssignmentLog.practicePathUuid }}
      </div>
    </div>

    <div *ngSwitchCase="EntityCachingState.ErrorDuringQuery" style="font-style: italic;">
      Hiba a vizsgaútvonal lekérdezés közben
    </div>
  </div>
</div>

<!-- Critical point -->
<div [ngSwitch]="criticalPointCachingState" style="font-size: 0.9rem; margin-top: 5px;">
  <div *ngSwitchCase="EntityCachingState.CachedAndExist">
    <div style="font-weight: 500;" (click)="openCriticalPointLogsInDialog()"
      [class.clickable-text]="isLogEntitySameAsOpenedLoggedEntity(criticalPointAssignmentLog.criticalPointUuid) == false">
      {{ criticalPointTitle }}
    </div>
    <div class="critical-point-description">
      {{ criticalPointDescription }}
    </div>
  </div>

  <div *ngSwitchCase="EntityCachingState.CachedAndNotExist" style="font-style: italic;">
    <div>
      Nem létező kritikus pont (valószínűleg törölve)
    </div>
    <div (click)="openCriticalPointLogsInDialog()"
      [class.clickable-text]="isLogEntitySameAsOpenedLoggedEntity(criticalPointAssignmentLog.criticalPointUuid) == false">
      {{ criticalPointAssignmentLog.criticalPointUuid }}
    </div>
  </div>

  <div *ngSwitchCase="EntityCachingState.ErrorDuringQuery" style="font-style: italic;">
    Hiba a kritikus pont lekérdezés közben
  </div>
</div>