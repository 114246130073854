import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'practiseTime'})
export class PractiseTimePipe implements PipeTransform {
  transform(milliseconds: number):string {
    if(milliseconds === undefined || milliseconds === NaN) {
        return "-";
      }
  
      return Math.floor((milliseconds / 3600000)) + " óra " + Math.floor((milliseconds / 60000)) % 60 + " perc";
  }
}