import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NotifierService } from 'angular-notifier';
import { Vendor } from 'src/app/classes/model/vendor';
import { VendorService } from 'src/app/services/vendor.service';
import { VendorDataFormComponent, VendorDataFormResult } from '../vendor-data-form/vendor-data-form.component';

@Component({
  selector: 'app-create-vendor-dialog',
  templateUrl: './create-vendor-dialog.component.html',
  styleUrls: ['./create-vendor-dialog.component.scss']
})
export class CreateVendorDialogComponent implements OnInit {
  @ViewChild(VendorDataFormComponent, { static: true }) vendorDataFormComponent:VendorDataFormComponent;

  registrateAsClassroomInstructor:boolean = false;

  registrationInProgress:boolean = false;

  constructor(
    private vendorService:VendorService,
    private notifierService:NotifierService,
    private dialogRef:MatDialogRef<CreateVendorDialogComponent, boolean>
  ) { }

  public static open(dialogService:MatDialog):MatDialogRef<CreateVendorDialogComponent, boolean> {
    return dialogService.open<CreateVendorDialogComponent, any, boolean>(CreateVendorDialogComponent, { disableClose: true  });
  }

  public ngOnInit(): void { }

  /**
   * Handles the registration button click. It makes an API call through the vendor service to registrate the
   * vendor with the given infromations in the vendor data form.
   */
  public async onRegistrateVendorButtonClick():Promise<void> {
    // Set the registration in progress to true
    this.registrationInProgress = true;

    // Get the form data from the from subcomponent
    const vendorDataFormResult:VendorDataFormResult = this.vendorDataFormComponent.getFormData();

    // Set the vendor fields
    const vendorFields:Partial<Vendor> = {
      email: vendorDataFormResult.email,
      name: vendorDataFormResult.name,
      type: vendorDataFormResult.type,
      phoneNumber: vendorDataFormResult.phoneNumber
    };

    try {
      // Registrate the vendor
      await this.vendorService.registrateVendor(
        vendorFields,
        vendorDataFormResult.canSendPaymentRequest,
        this.registrateAsClassroomInstructor
      );
      // On succes, close the dialog and show a notification about it
      this.dialogRef.close(true);
      this.notifierService.notify("success", "Sikeres viszonteladó létrehozás");
    } catch(error:any) {
      // If the error is vendor already exists, show an notification
      if(error.error?.error === "VENDOR_ALREADY_EXISTS") {
        this.notifierService.notify("error", "A viszonteladó már regisztrálva van ezzel az e-mail címmel.");
      }
    }

    // Set the registration in progress to false
    this.registrationInProgress = false;
  }

  /**
   * Returns that the vendor data for is valid.
   * 
   * @returns true, if the form valid, false otherwise
   */
  protected isFormValid():boolean {
    return this.vendorDataFormComponent.isFormValid();
  }
  
}
