import { Component, HostListener, OnInit } from '@angular/core';
import { SettingsService } from './services/common/settings.service';
import { AppInitializationService } from './services/app-initialization.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  /**
   * Before we unload the actual window we clear the session storage
   */
  @HostListener('window:beforeunload')
  clearSessionStorage() {
    sessionStorage.clear();
  }

  constructor(
    private appInitializationService:AppInitializationService
  ) { }

  public ngOnInit():void {
    // Initalizes the main application component's service
    this.appInitializationService.initialize();
  }
}
