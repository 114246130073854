import { PracticeVideoManagerDialogData } from './../practice-video-manager-dialog/practice-video-manager-dialog.component';
import { Component, ElementRef, Input, OnInit, ViewContainerRef } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Subscription } from "rxjs";
import {
  DisplayedPathEditState,
  pathEditStates,
  PracticePathBaseInformations,
} from "src/app/classes/model/practice-path";
import { Permission } from "src/app/classes/model/permissions";
import { ConfirmationDialogService } from "src/app/modules/confirmation-dialog/services/confirmation-dialog.service";
import { PracticePathService } from "src/app/services/practice-path.service";
import { PracticeVideoManagerDialogComponent } from "../practice-video-manager-dialog/practice-video-manager-dialog.component";
import { PracticeCity } from 'src/app/classes/model/practice-city';
import { PracticePathsPageDataService } from '../services/practice-paths-page-data.service';
import { matchingPropertyPredicate } from 'src/app/functions/misc';
import { NarrationsDuringRecordingDialogComponent } from "../narrations-during-recording-dialog/narrations-during-recording-dialog.component";
import { PermissionService } from 'src/app/services/common/permission.service';
import { PracticalModuleLogsDialogComponent } from 'src/app/modules/practical-module-logs/components/practical-module-logs-dialog/practical-module-logs-dialog.component';

@Component({
  selector: "app-practice-path-card",
  templateUrl: "./practice-path-card.component.html",
  styleUrls: ["./practice-path-card.component.scss"],
})
export class PracticePathCardComponent implements OnInit {
  @Input() practicePath: PracticePathBaseInformations;
  @Input() practiceCity: PracticeCity;

  pathEditStates: ReadonlyArray<DisplayedPathEditState> = pathEditStates;

  userCanEdit: boolean = false;
  userCanConvertVideos: boolean = false;

  numberOfNonVerifiedCriticalPoints:number;
  numberOfCriticalPointsWithoutStopPoint:number;

  constructor(
    private dialogService: MatDialog,
    private confirmationDialogService: ConfirmationDialogService,
    private practicePathService: PracticePathService,
    private snackBarService: MatSnackBar,
    private permissionService:PermissionService,
    public elementRef:ElementRef<HTMLElement>,
    private viewContainerRef:ViewContainerRef,
    private practicePathsPageDataService:PracticePathsPageDataService
  ) { }

  ngOnInit(): void {
    // Get the permissions
    this.userCanEdit = this.permissionService.isLoggedUserHasPermission(Permission.PracticePathWrite);
    this.userCanConvertVideos = this.permissionService.isLoggedUserHasPermission(Permission.PracticeVideoConvert);

    this.numberOfNonVerifiedCriticalPoints = this.practicePathService.getNumberOfNonVerifiedCriticalPointsOfPath(
      this.practiceCity,
      this.practicePath
    );
    this.numberOfCriticalPointsWithoutStopPoint = this.practicePathService.getNumberOfCriticalPointsWithoutStopPointOfPath(this.practicePath);
  }

  /**
   * Gets the edit state name based on the provided key. If no edit state is matches, unknown state string returned.
   *
   * @param editStateKey the edit state's key
   *
   * @returns the name of the state
   */
  public getEditStateName(editStateKey: string): string {
    return (
      this.pathEditStates.find(matchingPropertyPredicate("key", editStateKey))
        ?.displayedName ?? "Ismerelen állapot"
    );
  }

  /**
   * Gets the edit state's color based on the provided key. If no edit state is matches, default (black) is returned.
   *
   * @param editStateKey the edit state's key
   *
   * @returns the color of the state
   */
  public getEditStateColor(editStateKey: string): string {
    return (
      this.pathEditStates.find((editState) => editState.key === editStateKey)
        ?.color ?? "black"
    );
  }

  getZonesString(){
    return this.practiceCity.zones
      .filter((zone) => this.practicePath.zoneUuids.includes(zone.uuid))
      .map((z) => z.name)
      .join(", ");
  }

  getNodeFromUuid(nodeUuid: string) {
    return this.practiceCity.nodes.find(
      (node) => node.uuid == nodeUuid
    );
  }

  public openVideoManagerDialog(practicePath:PracticePathBaseInformations): void {
    if (!this.userCanConvertVideos) {
      return;
    }

    const dialogRef: MatDialogRef<PracticeVideoManagerDialogComponent> =
      this.dialogService.open<PracticeVideoManagerDialogComponent, PracticeVideoManagerDialogData>(PracticeVideoManagerDialogComponent, {
        data: {
          practicePathUuid: practicePath.uuid,
        },
        disableClose: true,
        viewContainerRef: this.viewContainerRef
      });

    const dialogAfterCloseSubscription: Subscription = dialogRef.afterClosed().subscribe(
      (practicePath:PracticePathBaseInformations) => {
        dialogAfterCloseSubscription.unsubscribe();
        this.practicePath.video = practicePath.video;
      }
    );
  }

  // A dialógus tartalmát a template definiálja ng-template-ként
  public onTapPathName(templateRef){
    this.dialogService.open(templateRef, {
      viewContainerRef: this.viewContainerRef
    });
  }

  public handleDeleteButtonClick(): void {
    this.confirmationDialogService.open(
      `"${this.practicePath.name}" vizsgaútvonal törlése`,
      "Biztos benne?",
      async () => {
        try {
          await this.practicePathsPageDataService.removePracticePath(this.practicePath.uuid);

          this.snackBarService.open(
            "Vizsgaútvonal sikeresen törölve.",
            "Bezár",
            { duration: 2000, panelClass: ["mat-snackbar-success"] }
          );
        } catch (error: any) {}
      }
    );
  }

  protected hasMinimalBitrateVideo():boolean {
    return this.practicePath.video?.rawVideoConvertedMinimalBitrate != undefined;
  }

  protected hasReleasedVideo():boolean {
    return this.practicePath.video?.releasedVideo != undefined;
  }

  protected openNarrationsDuringRecordingDialog(practicePath:PracticePathBaseInformations):void {
    if(practicePath.audio == undefined) {
      return;
    }

    const dialogRef:MatDialogRef<
      NarrationsDuringRecordingDialogComponent,
      PracticePathBaseInformations
    > = NarrationsDuringRecordingDialogComponent.open(
      this.dialogService,
      {
        practicePathUuid: practicePath.uuid,
        initialTab: "list"
      }
    );

    dialogRef.afterClosed().subscribe(
      (practicePath?:PracticePathBaseInformations) => {
        if(practicePath) {
          this.practicePath = practicePath;
        }
      }
    );
  }

  /**
   * Opens the practical module logs dialog for the practice path.
   */
  protected openPracticePathLogs():void {
    PracticalModuleLogsDialogComponent.open(
      this.dialogService,
      {
        entityType: "practice_path",
        entityKey: this.practicePath.uuid,
        dialogTitle: "Vizsgaútvonal logok",
        entityName: this.practicePath.name
      }
    );
  }
}
